import React, { useEffect, useState } from 'react';

import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { ClickAwayListener } from '@mui/material';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';

import { Box } from '@mui/system';

import {
  chatActions,
  getChannelUrl,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import CustomMessage from '../Admin/ChatWindow/CustomMessage';

import CannedMessages from './CannedMessages';
import ChatButton from './ChatButton';
import styles from './styles';

const successAction = chatActions.createGroupChannel;

const FloatingChat = () => {
  const dispatch = useAppDispatch();

  const [show, setShow] = useState(false);
  const channelUrl = useAppSelector(getChannelUrl);

  const success = useAppSelector((state) =>
    successSelector([successAction], state));

  useEffect(() => {
    if (success) {
      setShow(true);
      dispatch(resetStatus([successAction]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShow(false);
      }}
    >
      <Box>
        <Box sx={styles.container}>
          {show && channelUrl && (
            <Box sx={styles.groupChannelContainer}>
              <GroupChannel
                renderChannelHeader={(props) =>
                  (
                    <GroupChannelHeader
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...props}
                      renderRight={() =>
                        <div />}
                      renderLeft={() =>
                        <div />}
                    />
                  )}
                renderMessage={CustomMessage}
                channelUrl={channelUrl}
                renderMessageInput={CannedMessages}
              />
            </Box>
          )}
        </Box>
        <ChatButton show={show} setShow={setShow} />
      </Box>
    </ClickAwayListener>
  );
};

export default FloatingChat;
