import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AdminLegendVariant,
  IAdminApproveParams,
  IAdminBookAvailabilityParams,
  IAdminCalendarParams,
  IAdminEditWorkshiftParams,
  IAdminIgnoreRequestParams,
  IAdminSearchAPIParams,
  IExpressBookingAPIParams,
  IAdminPendingActivationAPIParams,
  IPharmacistSubmission,
  IPharmacyDetails,
  IRequestCancellationParams,
  IValidateExpressBookingAPIParams,
  IAdminTakeOwnershipAPIParams,
  IAdminCreateSoftwareApiParams,
  IAdminUpdateSoftwareApiParams,
  IAdminCreatePharmacistCapacityApiParams,
  IAdminUpdatePharmacistCapacityApiParams,
  IAdminConfiguration,
  ISalesforceAdmin,
  IAdminCreatePharmacyChainApiParams,
  IAdminUpdatePharmacyChainApiParams,
  IUpdatePharmacistRegions,
  IPendingCancellationApiParmas,
  IAdminApprovePendingCancellationApiParams,
  IAdminRejectPendingCancellationApiParams,
  IAdminActivePharmacistListApiParams,
  IAdminCreateAvailabilityApiParams,
  IAdminCreateNoteApiParams,
  IAdminUpdateNoteApiParams,
  IAdminPharmacistBookingsAPIParams,
  IAdminDeletePharmacistAvailabilityAPIParams,
  IDeactivePharmacistApiParams,
  IAdminListOfPharmaciesApiParams,
  IAdminPharmacistHistoryApiParams,
  IDeactivatedPharmacistListApiParams,
  IAdminCreateWorkshiftParams,
  IAdminListOfPharmacistsApiParams,
  IAdminIncompatibleMatchApiParams,
  IAdminUpdateIncompatibleMatchApiParams,
  IAdminPharmacistHistoryBaseApiParams,
  IAdminPharmacyWorkshiftsList,
  IAdminPharmacyWorkshiftsListAPIParams,
  IAdminDeleteWorkshift,
  IAdminPharmacyListBaseParams,
  IAdminDisapprove,
  IAdminIncompatibleMatchesSearch,
  IAdminReportsAPIParams,
  ICalendarEventType,
  IApproveAdminsApiParams,
  IAdminListingApiParams,
  IAdminDeleteSoftwaresAPIParams,
  IAdminDeletePharmacyChainsAPIParams,
  IAdminDeletePharmacistCapacitiesAPIParams,
  ICreateAdminParams,
  IUpdateAdminParams,
  IAdminCreateContactPreferenceApiParams,
  IAdminDeleteContactPreferenceApiParams,
  IAdminUpdateContactPreferenceApiParams,
  IAdminPharmacistDetailsSearchApiParams,
  IAdminCreatePharmacyNoteApiParams,
  IAdminUpdatePharmacyNoteApiParams,
  IAdminBroadcastUserGroupsPharmacistApiParams,
  IAdminBroadcastUserGroupsPharmacyApiParams,
  IAdminBroadcastGroupListApiParams,
  IAdminCreateBroadcastGroupApiParams,
  IAdminUpdateBroadcastGroupApiParams,
  IBroadcastNotificationsApiParams,
  IAdminSendBroadcastApiParams,
  IAdminLogsGenericParams,
  IDeleteBroadcastGroupApiParams,
  IDeleteBroadcastNotificationApiParams,
  IUpdateAdminInfo,
  ICreatePharmacyAdmin,
  IUpdatePharmacyAdmin,
  IGetPharmaciesForPharmacyAdminParams,
  IDeletePharmacyAdminParams,
  IAppointPharmacyParams,
  IBroadcastGroupParams,
  IPharmacyAdminListApiParams,
  IAdminDashboardFilterAPIParams,
  IAdminMultipleAvailabilityCalendar,
  IAdminMultipleAvailabilityCalendarParams,
  IAdminPharmacistVacationsDeleteParams,
  IAdminStatisticsParams,
  IAdminDeleteSingleWorkshift,
  IAllClientApiParams,
  IAdminResendEmail,
} from '../models/AdminModels';
import AdminController from '../controllers/AdminController';
import { statusDispatch } from './globalActions';
import { PendingCancellationTabTypes, TypeOfUser } from '../helpers/Constants';
import {
  IPreSubmitVacationsParams,
  IVacationsParams,
} from '../models/PharmacistModels';

const admin = 'admin';
const broadcastGroupAction = (action: string) =>
  `${admin}/${action}/broadcast/group`;

export const adminActions = {
  getAdminCalendar: `${admin}/calendar`,
  getAdminMap: `${admin}/map`,
  getActiveUsers: `${admin}/activeUsers`,
  getAdminEvent: `${admin}/get/event`,
  getWorkshiftDetails: `${admin}/workshifts/details`,
  deleteWorkshift: `${admin}/workshifts/delete`,
  editAdminWorkshift: `${admin}/workshifts/edit`,
  getRequestedPharmacists: `${admin}/requested/pharmacists/get`,
  getRequestedDetails: `${admin}/requested/details/get`,
  getIgnoreRequestDetails: `${admin}/requested/ignore/details/get`,
  getApproveRequestDetails: `${admin}/requested/approve/details/get`,
  getBookedPharmacistDetails: `${admin}/booked/pharmacist/details/get`,
  approveBooking: `${admin}/approve/booking`,
  requestCancellation: `${admin}/request/cancellation`,
  ignoreRequest: `${admin}/requested/ignore`,
  getAdminRatingItems: `${admin}/ratingItems/get`,
  getPharmacistRating: `${admin}/pharmacistRating/get`,
  resetValue: `${admin}/value/reset`,
  getMatchingAvailabilities: `${admin}/get/matchingAvailabilities`,
  expressBooking: `${admin}/express-booking`,
  getBookAvailablity: `${admin}/get/getBookAvailablity`,
  bookAvailablity: `${admin}/book/availablity`,
  getRequestCancellation: `${admin}/get/requestCancellation`,
  getOtherAvailabilities: `${admin}/get/otherAvailabilities`,
  getPharmacyDetails: `${admin}/get/pharmacyDetails`,
  getPharmacistDetails: `${admin}/get/pharmacistDetails`,
  validateExpressBooking: `${admin}/validate/expressBooking`,
  searchUsers: `${admin}/users/search`,
  getPendingActivations: `${admin}/get/pendingActivations`,
  submitPharmacyDetails: `${admin}/submit/pharmacyDetails`,
  submitPharmacistDetails: `${admin}/submit/pharmacistDetails`,
  adminTakeOwnership: `${admin}/takeOwnership`,
  takeOwnership: `${admin}/takeOwnership`,
  activateUser: `${admin}/activate/user`,
  uploadPharmacistPhoto: `${admin}/upload/pharmacistPhoto`,
  deletePendingUser: `${admin}/delete/pending/user`,
  getDeletedUsers: `${admin}/get/deletedUsers`,
  deletedUserProfile: `${admin}/get/deletedUserProfile`,
  uploadPharmacistResume: `${admin}/upload/pharmacistResume`,
  getSoftwareList: `${admin}/get/softwareList`,
  getSoftware: `${admin}/get/software`,
  createSoftware: `${admin}/create/software`,
  updateSoftware: `${admin}/update/software`,
  getPharmacistCapacityList: `${admin}/get/pharmacistCapacityList`,
  getPharmacistCapacity: `${admin}/get/pharmacistCapacity`,
  createPharmacistCapacity: `${admin}/create/pharmacistCapacity`,
  updatePharmacistCapacity: `${admin}/update/pharmacistCapacity`,
  getContactPreferencesList: `${admin}/get/contactPreferencesList`,
  getAdminConfiguration: `${admin}/get/configuration`,
  getSalesforceAdmins: `${admin}/get/salesforceAdmins`,
  editAdminConfiguration: `${admin}/edit/configuration`,
  deleteSalesforceAdmins: `${admin}/delete/salesforceAdmins`,
  createSalesforceAdmins: `${admin}/create/salesforceAdmins`,
  editSalesforceAdmins: `${admin}/edit/salesforceAdmins`,
  getPharmacyChainList: `${admin}/get/pharmacyChainList`,
  getPharmacyChain: `${admin}/get/pharmacyChain`,
  createPharmacyChain: `${admin}/create/pharmacyChain`,
  updatePharmacyChain: `${admin}/update/pharmacyChain`,
  getPharmacistRegions: `${admin}/get/pharmacistRegions`,
  updatePharmacistRegions: `${admin}/update/pharmacistRegions`,
  getPendingCancellations: `${admin}/get/pendingCancellations`,
  getAdminPharmacistResumes: `${admin}/get/pharmacistResumes`,
  getPendingCancellationDetails: `${admin}/get/pendingCancellationDetails`,
  approvePendingCancellation: `${admin}/approve/pendingCancellation`,
  rejectPendingCancellation: `${admin}/reject/pendingCancellation`,
  getCancelledBookings: `${admin}/get/cancelledBookings`,
  getCancelledBookingDetails: `${admin}/get/cancelledBookingsDetails`,
  getActivePharmacistList: `${admin}/get/activePharmacistList`,
  getActivePharmacistListPagination: `${admin}/get/activePharmacistList/pagination`,
  createAvailabilities: `${admin}/create/availabilities`,
  getRejectedCancellations: `${admin}/get/rejectedCancellations`,
  getAllNotes: `${admin}/get/allNotes`,
  getNote: `${admin}/get/note`,
  createNote: `${admin}/create/note`,
  updateNote: `${admin}/update/note`,
  getPharmacistBookingsList: `${admin}/get/pharmacistBookingList`,
  getPharmacistBookingsListPagination: `${admin}/get/PharmacistBookingsListPagination`,
  getPharmacistBooking: `${admin}/get/pharmacistBooking`,
  showAvailabilities: `${admin}/show/availabilities`,
  deletePharmacistAvailabilities: `${admin}/delete/pharmacistAvailabilities`,
  matchingWorkshifts: `${admin}/find/matchingWorkshifts`,
  showAvailabilitiesPagination: `${admin}/show/availabilities/pagination`,
  hidePharmacist: `${admin}/hide/pharmacists`,
  deactivatePharmacist: `${admin}/deactivate/pharmacists`,
  adminPharmacistPictures: `${admin}/get/pharmacistPictures`,
  getPendingActivationsCount: `${admin}/get/pendingActivationsCount`,
  getOwnersList: `${admin}/get/ownersList`,
  setActiveUsersSegment: `${admin}/set/activeUsersSegment`,
  listOfPharmacies: `${admin}/get/listOfPharmacies`,
  listOfPharmaciesPagination: `${admin}/get/listOfPharmacies/pagination`,
  pharmacistNotes: `${admin}/pharmacist/get/notes`,
  pharmacistHistory: `${admin}/pharmacist/get/history`,
  pharmacistHistoryPagination: `${admin}/pharmacist/get/history/pagination`,
  getDeactivatedPharmacists: `${admin}/pharmacists/deactivated`,
  getHiddenPharmacists: `${admin}/pharmacists/hidden`,
  activatePharmacist: `${admin}/pharmacists/activate`,
  getDeletedPharmacist: `${admin}/pharmacist/deleted`,
  showPharmacist: `${admin}/pharmacists/show`,
  getActivePharmacyList: `${admin}/get/activePharmacyList`,
  getActivePharmacyListPagination: `${admin}/get/activePharmacyList/pagination`,
  createWorkshift: `${admin}/create/workshift`,
  getPublishedSoftwareList: `${admin}/get/publishedSoftwareList`,
  listOfPharmacists: `${admin}/get/listOfPharmacists`,
  listOfPharmacistsPagination: `${admin}/get/listOfPharmacists/pagination`,
  pharmacytNotes: `${admin}/pharmacy/get/notes`,
  pharmacyHistory: `${admin}/pharmacy/get/history`,
  pharmacyHistoryPagination: `${admin}/pharmacy/get/history/pagination`,
  getIncompatibleMatchList: `${admin}/get/incompatible-matches`,
  getIncompatibleMatchListPagination: `${admin}/get/incompatible-matches/pagination`,
  updateIncompatibleMatch: `${admin}/update/incompatible-matches`,
  deletePermanentlyPendingUsers: `${admin}/delete/pending-activations`,
  getPharmacyWorkshiftsList: `${admin}/get/pharmacyWorkshiftsList`,
  getPharmacyWorkshiftsListPagination: `${admin}/get/PharmacyWorkshiftsListPagination`,
  deletePharmacyWorkshift: `${admin}/delete/pharmacyWorkshift`,
  deleteMultipleWorkshift: `${admin}/delete/multipleWorkshift`,
  getDeactivatedPharmacyList: `${admin}/get/deactivatedPharmacyList`,
  getDeletedPharmacyList: `${admin}/get/deletedPharmacyList`,
  disapprove: `${admin}/disapprove/user`,
  approve: `${admin}/approve/user`,
  incompatibleMatchesSearch: `${admin}/persist/incompatibleMatchesSearch`,
  getAdminReports: `${admin}/get/adminReports`,
  getAllPharmacistAvailabilies: `${admin}/get/all/availabilities`,
  getAdminReportsFilter: `${admin}/get/adminReports/filter`,
  getAdminReportsPDF: `${admin}/get/adminReports/PDF`,
  approveAdmins: `${admin}/admins/approve`,
  disapproveAdmins: `${admin}/admins/disapprove`,
  getActiveAdmins: `${admin}/get/admins/active`,
  getInactiveAdmins: `${admin}/get/admins/inactive`,
  deletePharmacyChains: `${admin}/delete/pharmacyChains`,
  deletePharmacistCapacities: `${admin}/delete/pharmacistCapacities`,
  deleteSoftwares: `${admin}/delete/softwares`,
  createAdmin: `${admin}/create`,
  updateAdmin: `${admin}/update`,
  getAdminProfile: `${admin}/get/profile`,
  getAdminDetails: `${admin}/get/details`,
  createContactPreference: `${admin}/create/contactPreference`,
  updateContactPreference: `${admin}/update/contactPreference`,
  deleteContactPreference: `${admin}/delete/contactPreference`,
  getContactPreference: `${admin}/get/contactPreference`,
  getAdminPharmacistDetailsSearch: `${admin}/get/pharmacist/detailsSearch`,
  getPharmacistDetailsSearchFilters: `${admin}/get/pharmacist/detailsSearchfilters`,
  getAllPharmacyNotes: `${admin}/get/allPharmacyNotes`,
  getPharmacyNote: `${admin}/get/pharmacyNote`,
  createPharmacyNote: `${admin}/create/pharmacyNote`,
  updatePharmacyNote: `${admin}/update/pharmacyNote`,
  getPharmaciesInfo: `${admin}/get/pharmaciesInfo`,
  getRatingDetails: `${admin}/get/ratingDetails`,
  getAdminSettings: `${admin}/get/settings`,
  permDeleteCancelledBookings: `${admin}/delete/cancelled-bookings`,
  permDeleteRejectedCancellations: `${admin}/delete/cancelled-bookings`,
  getBroadcastGroupUsers: `${broadcastGroupAction('get')}/users`,
  getBroadcastGroupUsersPagination: `${broadcastGroupAction(
    'get'
  )}/users/pagination`,
  createBroadcastGroup: `${broadcastGroupAction('create')}`,
  updateBroadcastGroup: `${broadcastGroupAction('update')}`,
  deleteBroadcastGroup: `${broadcastGroupAction('delete')}`,
  getBroadcastList: `${broadcastGroupAction('get')}/list`,
  getAllBroadcastGroups: `${broadcastGroupAction('get')}/all/group`,
  getBroadcastGroup: `${broadcastGroupAction('get')}/group`,
  getBroadcastFilter: `${broadcastGroupAction('get')}/filter`,
  getBroadcastNotifications: `${admin}/broadcast/get/notifications`,
  getBroadcastRecipients: `${admin}/broadcast/get/recipients`,
  sendBroadcastNotification: `${admin}/broadcast/send/notification`,
  getBroadcastNotification: `${admin}/broadcast/get/notification`,
  deleteBroadcastNotification: `${admin}/broadcast/delete/notification`,
  getLogsFilters: `${admin}/get/logs/filters`,
  getWorkshiftLogs: `${admin}/get/logs/workshifts`,
  getAvailabilitiesLogs: `${admin}/get/logs/availabilities`,
  getActivitiesLogs: `${admin}/get/logs/activities`,
  getBookingLogs: `${admin}/get/logs/booking`,
  getAdminInfo: `${admin}/get/adminInfo`,
  updateAdminInfo: `${admin}/update/adminInfo`,
  getAdminPicture: `${admin}/get/adminPicture`,
  uploadAdminPicture: `${admin}/upload/adminPicture`,
  getPharmacyAdminList: `${admin}/get/pharmacy-admin/list`,
  getPharmacyAdminDetails: `${admin}/get/pharmacy-admin/details`,
  createPharmacyAdmin: `${admin}/create/pharmacy-admin`,
  editPharmacyAdmin: `${admin}/update/pharmacy-admin`,
  pharmacyAdminProfile: `${admin}/get/pharmacy-admin/profile`,
  getPharmaciesForPharmacyAdmin: `${admin}/get/pharmacies/pharmacy-admin`,
  getPharmaciesForPharmacyAdminPagination: `${admin}/get/pharmacies/pharmacy-admin/pagination`,
  deletePharmacyAdmin: `${admin}/delete/pharmacy-admin`,
  appointExistingPharmacy: `${admin}/appoint/pharmacy`,
  appointedPharmaciesList: `${admin}/appointed/pharmacies`,
  watchlists: `${admin}/get/watchlists`,
  getBroadcastGroupSelectedUsers: `${broadcastGroupAction(
    'get'
  )}/group/selected-users`,
  getBroadcastGroupSelectedUsersPagination: `${broadcastGroupAction(
    'get'
  )}/group/selected-users/pagination`,
  getAdminCalendarFilters: `${admin}/get/calendar/filters`,
  getAdminPharmacistVacations: `${admin}/get/pharmacist/vacations`,
  adminPreSubmitVacations: `${admin}/preSubmit/pharmacist/vacations`,
  adminPharmacistVacations: `${admin}/create/pharmacist/vacations`,
  deleteAdminPharmacistVacations: `${admin}/delete/pharmacist/vacations`,
  adminPreSubmitAvailabilities: `${admin}/preSubmit/pharmacist/availabilities`,
  preSubmitDeleteAvailabilities: `${admin}/preSubmit/pharmacist/delete/availabilities`,
  activeUsersStatistics: `${admin}/statistics/activeUsers`,
  cancellationStatistics: `${admin}/statistics/cancellations`,
  financialStatistics: `${admin}/statistics/financial`,
  workshiftStatistics: `${admin}/statistics/workshift`,
  dailyStatistics: `${admin}/statistics/daily`,
  adminUploadPharmacyAdminPicture: `${admin}/upload/pharmacy-admin/picture`,
  adminGetPharmacyAdminPicture: `${admin}/get/pharmacy-admin/picture`,
  adminGetAllClients: `${admin}/get/allClients`,
  adminGetAllClientsPagination: `${admin}/get/pagination`,
  adminResendEmail: `${admin}/submit/resend-email`,
};

export const getAdminCalendar = createAsyncThunk(
  adminActions.getAdminCalendar,
  async (params: IAdminDashboardFilterAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.calendar(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminMap = createAsyncThunk(
  adminActions.getAdminMap,
  async (params: IAdminCalendarParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.map(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActiveUsers = createAsyncThunk(
  adminActions.getActiveUsers,
  async (
    { page, user }: { page: number; user: TypeOfUser },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.activeUsers(page, user);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminEvent = createAsyncThunk(
  adminActions.getAdminEvent,
  async (
    { date, type }: { date: string; type: AdminLegendVariant },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.getAdminEvent(type, date);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWorkshiftDetails = createAsyncThunk(
  adminActions.getWorkshiftDetails,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.workshiftDetails(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAdminWorkshift = createAsyncThunk(
  adminActions.deleteWorkshift,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteWorkshift(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editAdminWorkshift = createAsyncThunk(
  adminActions.editAdminWorkshift,
  async (params: IAdminEditWorkshiftParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.editWorkshift(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRequestedPharmacists = createAsyncThunk(
  adminActions.getRequestedPharmacists,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.requestedPharmacists(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRequestedDetails = createAsyncThunk(
  adminActions.getRequestedDetails,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.requestedDetails(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIgnoreDetails = createAsyncThunk(
  adminActions.getIgnoreRequestDetails,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.ignoreDetails(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminIgnoreRequest = createAsyncThunk(
  adminActions.ignoreRequest,
  async (params: IAdminIgnoreRequestParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.ignoreRequest(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getApproveDetails = createAsyncThunk(
  adminActions.getApproveRequestDetails,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.approveDetails(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminApproveBooking = createAsyncThunk(
  adminActions.approveBooking,
  async (params: IAdminApproveParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminApproveBookings(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminBookedPharmacistDetails = createAsyncThunk(
  adminActions.getBookedPharmacistDetails,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminBookedPharmacistDetails(
        bookingId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getMatchingAvailabilities = createAsyncThunk(
  adminActions.getMatchingAvailabilities,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getMatchingAvailabilities(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOtherAvailabilities = createAsyncThunk(
  adminActions.getOtherAvailabilities,
  async (workshiftId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getOtherAvailabilities(workshiftId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBookAvailablity = createAsyncThunk(
  adminActions.getBookAvailablity,
  async (
    {
      workshiftId,
      availabilityId,
    }: { workshiftId: string; availabilityId: string },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.getBookAvailablity(
        workshiftId,
        availabilityId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const bookAvailablity = createAsyncThunk(
  adminActions.bookAvailablity,
  async (params: IAdminBookAvailabilityParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.bookAvailablity(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminRequestCancellation = createAsyncThunk(
  adminActions.requestCancellation,
  async (params: IRequestCancellationParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.requestCancellation(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminRatingItems = createAsyncThunk(
  adminActions.getAdminRatingItems,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminRatingItems();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminPharmacistRating = createAsyncThunk(
  adminActions.getPharmacistRating,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminPharmacistRating(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRequestCancellation = createAsyncThunk(
  adminActions.getRequestCancellation,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getRequestCancellation(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyDetails = createAsyncThunk(
  adminActions.getPharmacyDetails,
  async (pharmacyId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacyDetails(pharmacyId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const searchUsers = createAsyncThunk(
  adminActions.searchUsers,
  async (params: IAdminSearchAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.searchUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistDetails = createAsyncThunk(
  adminActions.getPharmacistDetails,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacistDetails(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const expressBooking = createAsyncThunk(
  adminActions.expressBooking,
  async (params: IExpressBookingAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.expressBooking(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const submitPharmacyDetails = createAsyncThunk(
  adminActions.submitPharmacyDetails,
  async (params: IPharmacyDetails, { rejectWithValue }) => {
    try {
      const resp = await AdminController.submitPharmacyDetails(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const validateExpressBooking = createAsyncThunk(
  adminActions.validateExpressBooking,
  async (params: IValidateExpressBookingAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.validateExpressBooking(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPendingActivations = createAsyncThunk(
  adminActions.getPendingActivations,
  async (params: IAdminPendingActivationAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.pendingActivation(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const submitPharmacistDetails = createAsyncThunk(
  adminActions.submitPharmacistDetails,
  async (params: IPharmacistSubmission, { rejectWithValue }) => {
    try {
      const resp = await AdminController.submitPharmacistDetails(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminTakeOwnership = createAsyncThunk(
  adminActions.takeOwnership,
  async (params: IAdminTakeOwnershipAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.takeOwnership(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminActivateUser = createAsyncThunk(
  adminActions.activateUser,
  async (params: IAdminTakeOwnershipAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminActivateUser(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadPharmacistPhoto = createAsyncThunk(
  adminActions.uploadPharmacistPhoto,
  async (
    { pharmacistId, form }: { pharmacistId: string; form?: any },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.uploadPharmacistPhoto(
        pharmacistId,
        form
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeletePendingUser = createAsyncThunk(
  adminActions.deletePendingUser,
  async (params: IAdminTakeOwnershipAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminDeletePendingUser(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetDeletedUsers = createAsyncThunk(
  adminActions.getDeletedUsers,
  async (params: IAdminPendingActivationAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminGetDeletedUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeletedUserProfile = createAsyncThunk(
  adminActions.deletedUserProfile,
  async (
    { userId, approved }: { userId: string; approved: boolean },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.deletedUserProfile(userId, approved);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetSoftwareList = createAsyncThunk(
  adminActions.getSoftwareList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getSoftwareList();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadPharmacistResume = createAsyncThunk(
  adminActions.uploadPharmacistResume,
  async (
    { pharmacistId, form }: { pharmacistId: string; form: any },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.uploadPharmacistResume(
        pharmacistId,
        form
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetSoftware = createAsyncThunk(
  adminActions.getSoftware,
  async (softwareId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getSoftware(softwareId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreateSoftware = createAsyncThunk(
  adminActions.createSoftware,
  async (params: IAdminCreateSoftwareApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createSoftware(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdateSoftware = createAsyncThunk(
  adminActions.updateSoftware,
  async (params: IAdminUpdateSoftwareApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updateSoftware(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacistCapacityList = createAsyncThunk(
  adminActions.getPharmacistCapacityList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacistCapacitiesList();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacistCapacity = createAsyncThunk(
  adminActions.getPharmacistCapacity,
  async (capacityId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacistCapacity(capacityId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreatePharmacistCapacity = createAsyncThunk(
  adminActions.createPharmacistCapacity,
  async (
    params: IAdminCreatePharmacistCapacityApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.createPharmacistCapacity(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdatePharmacistCapacity = createAsyncThunk(
  adminActions.updatePharmacistCapacity,
  async (
    params: IAdminUpdatePharmacistCapacityApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.updatePharmacistCapacity(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetContactPreferencesList = createAsyncThunk(
  adminActions.getContactPreferencesList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.contactPreferencesList();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminConfiguration = createAsyncThunk(
  adminActions.getAdminConfiguration,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminConfiguration();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyChainList = createAsyncThunk(
  adminActions.getPharmacyChainList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacyChainList();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSalesforceAdmins = createAsyncThunk(
  adminActions.getSalesforceAdmins,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getSalesforceAdmins();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyChain = createAsyncThunk(
  adminActions.getPharmacyChain,
  async (chainId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacyChain(chainId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createSalesforceAdmins = createAsyncThunk(
  adminActions.createSalesforceAdmins,
  async (params: ISalesforceAdmin, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createSalesforceAdmins(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreatePharmacyChain = createAsyncThunk(
  adminActions.createPharmacyChain,
  async (params: IAdminCreatePharmacyChainApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createPharmacyChain(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editSalesforceAdmins = createAsyncThunk(
  adminActions.editSalesforceAdmins,
  async (params: ISalesforceAdmin, { rejectWithValue }) => {
    try {
      const resp = await AdminController.editSalesforceAdmins(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editAdminConfiguration = createAsyncThunk(
  adminActions.editAdminConfiguration,
  async (params: IAdminConfiguration, { rejectWithValue }) => {
    try {
      const resp = await AdminController.editAdminConfiguration(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteSalesforceAdmins = createAsyncThunk(
  adminActions.deleteSalesforceAdmins,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteSalesforceAdmins(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdatePharmacyChain = createAsyncThunk(
  adminActions.updatePharmacyChain,
  async (params: IAdminUpdatePharmacyChainApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updatePharmacyChain(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistRegions = createAsyncThunk(
  adminActions.getPharmacistRegions,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacistRegions(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminPharmacistResumes = createAsyncThunk(
  adminActions.getAdminPharmacistResumes,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminPharmacistResumes(
        pharmacistId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePharmacistRegions = createAsyncThunk(
  adminActions.updatePharmacistRegions,
  async (params: IUpdatePharmacistRegions, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updatePharmacistRegions(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPendingCancellations = createAsyncThunk(
  adminActions.getPendingCancellations,
  async (params: IPendingCancellationApiParmas, { rejectWithValue }) => {
    try {
      const resp = await AdminController.pendingCancellation(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPendingCancellationDetails = createAsyncThunk(
  adminActions.getPendingCancellationDetails,
  async (
    { id, type }: { id: string; type: PendingCancellationTabTypes },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.pendingCancellationCodeDetails(
        id,
        type
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const approvePendingCancellation = createAsyncThunk(
  adminActions.approvePendingCancellation,
  async (
    params: IAdminApprovePendingCancellationApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.approvePendingCancellation(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const rejectPendingCancellation = createAsyncThunk(
  adminActions.rejectPendingCancellation,
  async (
    params: IAdminRejectPendingCancellationApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.rejectPendingCancellation(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCancelledBookings = createAsyncThunk(
  adminActions.getCancelledBookings,
  async (params: IPendingCancellationApiParmas, { rejectWithValue }) => {
    try {
      const resp = await AdminController.cancelledBookings(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCancelledBookingsDetails = createAsyncThunk(
  adminActions.getCancelledBookingDetails,
  async (cancellationId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getCancelledBookingDetails(
        cancellationId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivePharmacistList = createAsyncThunk(
  adminActions.getActivePharmacistList,
  async (params: IAdminActivePharmacistListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getActivePharmacistList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivePharmacistListPagination = createAsyncThunk(
  adminActions.getActivePharmacistListPagination,
  async (params: IAdminActivePharmacistListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getActivePharmacistList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreateAvailabilities = createAsyncThunk(
  adminActions.createAvailabilities,
  async (params: IAdminCreateAvailabilityApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createAvailabilities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRejectedCancellations = createAsyncThunk(
  adminActions.getRejectedCancellations,
  async (params: IPendingCancellationApiParmas, { rejectWithValue }) => {
    try {
      const resp = await AdminController.rejectedCancellation(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetAllNotes = createAsyncThunk(
  adminActions.getAllNotes,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAllAdminNotes(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetNote = createAsyncThunk(
  adminActions.getNote,
  async (pharmacistNoteId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminNote(pharmacistNoteId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreateNote = createAsyncThunk(
  adminActions.createNote,
  async (params: IAdminCreateNoteApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createAdminNote(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdateNote = createAsyncThunk(
  adminActions.updateNote,
  async (params: IAdminUpdateNoteApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updateAdminNote(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetAllPharmacyNotes = createAsyncThunk(
  adminActions.getAllPharmacyNotes,
  async (pharmacyId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAllPharmacyAdminNotes(pharmacyId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyNote = createAsyncThunk(
  adminActions.getPharmacyNote,
  async (adminPharmacyNoteId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacyAdminNote(
        adminPharmacyNoteId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreatePharmacyNote = createAsyncThunk(
  adminActions.createPharmacyNote,
  async (params: IAdminCreatePharmacyNoteApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createPharmacyAdminNote(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdatePharmacyNote = createAsyncThunk(
  adminActions.updatePharmacyNote,
  async (params: IAdminUpdatePharmacyNoteApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updatePharmacyAdminNote(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistBookingsList = createAsyncThunk(
  adminActions.getPharmacistBookingsList,
  async (params: IAdminPharmacistBookingsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.pharmacistBookingsList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistBooking = createAsyncThunk(
  adminActions.getPharmacistBooking,
  async (bookingId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.pharmacistBooking(bookingId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistBookingsListPaginations = createAsyncThunk(
  adminActions.getPharmacistBookingsListPagination,
  async (params: IAdminPharmacistBookingsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.pharmacistBookingsList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminShowAvailabilities = createAsyncThunk(
  adminActions.showAvailabilities,
  async (params: IAdminPharmacistBookingsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.showAvailabilities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeleteAvailability = createAsyncThunk(
  adminActions.deletePharmacistAvailabilities,
  async (
    params: IAdminDeletePharmacistAvailabilityAPIParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.deletePharmacistAvailability(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const findMatchingWorkshifts = createAsyncThunk(
  adminActions.matchingWorkshifts,
  async (availabilityId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.matchingWorkshifts(availabilityId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminShowAvailabilitiesPagination = createAsyncThunk(
  adminActions.showAvailabilitiesPagination,
  async (params: IAdminPharmacistBookingsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.showAvailabilities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeactivatePharmacist = createAsyncThunk(
  adminActions.deactivatePharmacist,
  async (params: IDeactivePharmacistApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deactivatePharmacist(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminHidePharmacist = createAsyncThunk(
  adminActions.hidePharmacist,
  async (params: IDeactivePharmacistApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.hidePharmacist(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPharmacistPictures = createAsyncThunk(
  adminActions.adminPharmacistPictures,
  async (pharmacistIds: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacistPictures(pharmacistIds);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPendingActivationsCount = createAsyncThunk(
  adminActions.getPendingActivationsCount,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPendingActivationsCount();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getOwnersList = createAsyncThunk(
  adminActions.getOwnersList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getOwnersList();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetListOfPharmacies = createAsyncThunk(
  adminActions.listOfPharmacies,
  async (params: IAdminListOfPharmaciesApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminListOfPharmacies(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetListOfPharmaciesPagination = createAsyncThunk(
  adminActions.listOfPharmaciesPagination,
  async (params: IAdminListOfPharmaciesApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminListOfPharmacies(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacistNotes = createAsyncThunk(
  adminActions.pharmacistNotes,
  async (params: IAdminPharmacistHistoryBaseApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacistNotes(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacistHistory = createAsyncThunk(
  adminActions.pharmacistHistory,
  async (params: IAdminPharmacistHistoryApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacistHistory(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacistHistoryPagination = createAsyncThunk(
  adminActions.pharmacistHistoryPagination,
  async (params: IAdminPharmacistHistoryApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacistHistory(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeactivatedPharmacists = createAsyncThunk(
  adminActions.getDeactivatedPharmacists,
  async (params: IDeactivatedPharmacistListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deactivatedPharmacistList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHiddenPharmacists = createAsyncThunk(
  adminActions.getHiddenPharmacists,
  async (params: IDeactivatedPharmacistListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.hiddenPharmacistList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminActivatePharmacist = createAsyncThunk(
  adminActions.activatePharmacist,
  async (params: IDeactivePharmacistApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.activatePharmacist(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminShowPharmacist = createAsyncThunk(
  adminActions.showPharmacist,
  async (params: IDeactivePharmacistApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.showPharmacist(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeletedPharmacist = createAsyncThunk(
  adminActions.getDeletedPharmacist,
  async (params: IDeactivatedPharmacistListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deletedPharmacistList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivePharmacyList = createAsyncThunk(
  adminActions.getActivePharmacyList,
  async (params: IAdminPharmacyListBaseParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getActivePharmacyList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivePharmacyListPagination = createAsyncThunk(
  adminActions.getActivePharmacyListPagination,
  async (params: IAdminPharmacyListBaseParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getActivePharmacyList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createWorkshiftAdmin = createAsyncThunk(
  adminActions.createWorkshift,
  async (params: IAdminCreateWorkshiftParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createWorkshiftAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPublishedSoftwareList = createAsyncThunk(
  adminActions.getPublishedSoftwareList,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPublishedSoftwareList();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetListOfPharmacists = createAsyncThunk(
  adminActions.listOfPharmacists,
  async (params: IAdminListOfPharmacistsApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminListOfPharmacists(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetListOfPharmacistsPagination = createAsyncThunk(
  adminActions.listOfPharmacistsPagination,
  async (params: IAdminListOfPharmacistsApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminListOfPharmacists(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyNotes = createAsyncThunk(
  adminActions.pharmacytNotes,
  async (params: IAdminPharmacistHistoryBaseApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacytNotes(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyHistory = createAsyncThunk(
  adminActions.pharmacyHistory,
  async (params: IAdminPharmacistHistoryApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacyHistory(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyHistoryPagination = createAsyncThunk(
  adminActions.pharmacyHistoryPagination,
  async (params: IAdminPharmacistHistoryApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacyHistory(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetIncompatibleMatches = createAsyncThunk(
  adminActions.getIncompatibleMatchList,
  async (params: IAdminIncompatibleMatchApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.incompatibleMatchList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyWorkshiftsList = createAsyncThunk(
  adminActions.getPharmacyWorkshiftsList,
  async (
    params: IAdminPharmacyWorkshiftsListAPIParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.pharmacyWorkshiftsList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetIncompatibleMatchesPagination = createAsyncThunk(
  adminActions.getIncompatibleMatchListPagination,
  async (params: IAdminIncompatibleMatchApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.incompatibleMatchList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyWorkshiftsListPaginations = createAsyncThunk(
  adminActions.getPharmacyWorkshiftsListPagination,
  async (
    params: IAdminPharmacyWorkshiftsListAPIParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.pharmacyWorkshiftsList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdateIncompatibleMatches = createAsyncThunk(
  adminActions.updateIncompatibleMatch,
  async (
    params: IAdminUpdateIncompatibleMatchApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.updateIncompatibleMatch(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPermanentlyDeleteSelected = createAsyncThunk(
  adminActions.deletePermanentlyPendingUsers,
  async (userId: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteSelectedPendingActivations(
        userId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePharmacyWorkshift = createAsyncThunk(
  adminActions.deletePharmacyWorkshift,
  async (params: IAdminDeleteSingleWorkshift, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deletePharmacyWorkshift(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteMulipleWorkshift = createAsyncThunk(
  adminActions.deleteMultipleWorkshift,
  async (params: IAdminDeleteWorkshift, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminDeleteMultiWorkshift(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeactivatedPharmacyList = createAsyncThunk(
  adminActions.getDeactivatedPharmacyList,
  async (params: IAdminPharmacyListBaseParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getDeactivatedPharmacyList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDeletedPharmacyList = createAsyncThunk(
  adminActions.getDeletedPharmacyList,
  async (params: IAdminPharmacyListBaseParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getDeletedPharmacyList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDisapprove = createAsyncThunk(
  adminActions.disapprove,
  async (params: IAdminDisapprove, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminDisapprove(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminApprove = createAsyncThunk(
  adminActions.approve,
  async (params: IAdminDisapprove, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminApprove(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminReports = createAsyncThunk(
  adminActions.getAdminReports,
  async (params: IAdminReportsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminReports(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const approveAdmins = createAsyncThunk(
  adminActions.approveAdmins,
  async (params: IApproveAdminsApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.approveAdmins(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllPharmacistAvailabilies = createAsyncThunk(
  adminActions.getAllPharmacistAvailabilies,
  async (
    params: IAdminMultipleAvailabilityCalendarParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.getAllPharmacistAvailabilities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminReportsPDF = createAsyncThunk(
  adminActions.getAdminReportsPDF,
  async (params: IAdminReportsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminReportsPDF(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const disapproveAdmins = createAsyncThunk(
  adminActions.disapproveAdmins,
  async (params: IApproveAdminsApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.disapproveAdmins(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminReportsFilters = createAsyncThunk(
  adminActions.getAdminReportsFilter,
  async (type: ICalendarEventType, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminReportsFilter(type);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActiveAdmins = createAsyncThunk(
  adminActions.getActiveAdmins,
  async (params: IAdminListingApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getActiveAdmins(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getInactiveAdmins = createAsyncThunk(
  adminActions.getInactiveAdmins,
  async (params: IAdminListingApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getInactiveAdmins(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeletePharmacyChains = createAsyncThunk(
  adminActions.deletePharmacyChains,
  async (params: IAdminDeletePharmacyChainsAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deletePharmacyChains(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeletePharmacistCapacities = createAsyncThunk(
  adminActions.deletePharmacistCapacities,
  async (
    params: IAdminDeletePharmacistCapacitiesAPIParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.deletePharmacistCapacities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeleteSoftwares = createAsyncThunk(
  adminActions.deleteSoftwares,
  async (params: IAdminDeleteSoftwaresAPIParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteSoftwares(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminCreateContactPreference = createAsyncThunk(
  adminActions.createContactPreference,
  async (
    params: IAdminCreateContactPreferenceApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.createContactPreference(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUpdateContactPreference = createAsyncThunk(
  adminActions.updateContactPreference,
  async (
    params: IAdminUpdateContactPreferenceApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.updateContactPreference(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminDeleteContactPreference = createAsyncThunk(
  adminActions.deleteContactPreference,
  async (
    params: IAdminDeleteContactPreferenceApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.deleteContactPreference(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetContactPreference = createAsyncThunk(
  adminActions.getContactPreference,
  async (contactPreferenceId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getContactPreference(
        contactPreferenceId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createAdmin = createAsyncThunk(
  adminActions.createAdmin,
  async (params: ICreateAdminParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  adminActions.updateAdmin,
  async (params: IUpdateAdminParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updateAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminProfile = createAsyncThunk(
  adminActions.getAdminProfile,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminProfile();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminDetails = createAsyncThunk(
  adminActions.getAdminDetails,
  async (userId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminDetails(userId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistDetailsSearch = createAsyncThunk(
  adminActions.getAdminPharmacistDetailsSearch,
  async (
    params: IAdminPharmacistDetailsSearchApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.getPharmacistDetailsSearch(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacistDetailsSearchFilters = createAsyncThunk(
  adminActions.getPharmacistDetailsSearchFilters,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacistDetailsSearchFilters();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmaciesInfo = createAsyncThunk(
  adminActions.getPharmaciesInfo,
  async (pharmacyId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmaciesInfo(pharmacyId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getRatingDetails = createAsyncThunk(
  adminActions.getRatingDetails,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getRatingDetails(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminSettings = createAsyncThunk(
  adminActions.getAdminSettings,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminSettings();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPermDeleteCancelled = createAsyncThunk(
  adminActions.permDeleteCancelledBookings,
  async (bookingIds: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteCancelledBookings(bookingIds);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastGroupUsers = createAsyncThunk(
  adminActions.getBroadcastGroupUsers,
  async (
    params:
      | IAdminBroadcastUserGroupsPharmacistApiParams
      | IAdminBroadcastUserGroupsPharmacyApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.getBroadcastGroupUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastGroupUsersPagination = createAsyncThunk(
  adminActions.getBroadcastGroupUsersPagination,
  async (
    params:
      | IAdminBroadcastUserGroupsPharmacistApiParams
      | IAdminBroadcastUserGroupsPharmacyApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.getBroadcastGroupUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPermDeleteRejected = createAsyncThunk(
  adminActions.permDeleteRejectedCancellations,
  async (cancellationIds: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteRejectedCancellations(
        cancellationIds
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastGroupsList = createAsyncThunk(
  adminActions.getBroadcastList,
  async (params: IAdminBroadcastGroupListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBroadcastList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAllBroadcastGroups = createAsyncThunk(
  adminActions.getAllBroadcastGroups,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBroadcastList({
        pagingModel: { page: 0, itemPerPage: 0 },
      });
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastFilter = createAsyncThunk(
  adminActions.getBroadcastFilter,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBroadcastFilter();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createBroadcastGroup = createAsyncThunk(
  adminActions.createBroadcastGroup,
  async (params: IAdminCreateBroadcastGroupApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createBroadcastGroups(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateBroadcastGroup = createAsyncThunk(
  adminActions.updateBroadcastGroup,
  async (params: IAdminUpdateBroadcastGroupApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updateBroadcastGroups(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBroadcastGroup = createAsyncThunk(
  adminActions.deleteBroadcastGroup,
  async (params: IDeleteBroadcastGroupApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deleteBroadcastGroup(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastGroup = createAsyncThunk(
  adminActions.getBroadcastGroup,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBroadcastGroup(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastNotifications = createAsyncThunk(
  adminActions.getBroadcastNotifications,
  async (params: IBroadcastNotificationsApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBroadcastNotifications(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getLogsFilters = createAsyncThunk(
  adminActions.getLogsFilters,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getLogsFilters();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastRecipients = createAsyncThunk(
  adminActions.getBroadcastRecipients,
  async (groupIds: Array<string>, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getGroupRecipients(groupIds);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWorkshiftLogs = createAsyncThunk(
  adminActions.getWorkshiftLogs,
  async (params: IAdminLogsGenericParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getWorkshiftLogs(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastNotification = createAsyncThunk(
  adminActions.getBroadcastNotification,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBroadcastNotification(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAvailabilitiesLogs = createAsyncThunk(
  adminActions.getAvailabilitiesLogs,
  async (params: IAdminLogsGenericParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAvailabilitiesLogs(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActivitiesLogs = createAsyncThunk(
  adminActions.getActivitiesLogs,
  async (params: IAdminLogsGenericParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getActivitiesLogs(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBookingLogs = createAsyncThunk(
  adminActions.getBookingLogs,
  async (params: IAdminLogsGenericParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getBookingLogs(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendBroadcastNotification = createAsyncThunk(
  adminActions.sendBroadcastNotification,
  async (params: IAdminSendBroadcastApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.sendBroadcast(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteBroadcastNotification = createAsyncThunk(
  adminActions.deleteBroadcastNotification,
  async (
    params: IDeleteBroadcastNotificationApiParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.deleteBroadcastNotification(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminInfo = createAsyncThunk(
  adminActions.getAdminInfo,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminInfo();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyAdminLists = createAsyncThunk(
  adminActions.getPharmacyAdminList,
  async (params: IPharmacyAdminListApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacyAdminList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateAdminInfo = createAsyncThunk(
  adminActions.updateAdminInfo,
  async (params: IUpdateAdminInfo, { rejectWithValue }) => {
    try {
      const resp = await AdminController.updateAdminInfo(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmacyAdminDetails = createAsyncThunk(
  adminActions.getPharmacyAdminDetails,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmacyAdminDetails(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminPicture = createAsyncThunk(
  adminActions.getAdminPicture,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminPicture();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createPharmacyAdmin = createAsyncThunk(
  adminActions.createPharmacyAdmin,
  async (params: ICreatePharmacyAdmin, { rejectWithValue }) => {
    try {
      const resp = await AdminController.createPharmacyAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const editPharmacyAdmin = createAsyncThunk(
  adminActions.editPharmacyAdmin,
  async (params: IUpdatePharmacyAdmin, { rejectWithValue }) => {
    try {
      const resp = await AdminController.editPharmacyAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyAdminProfile = createAsyncThunk(
  adminActions.pharmacyAdminProfile,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.pharmacyAdminProfile();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const uploadAdminPicture = createAsyncThunk(
  adminActions.uploadAdminPicture,
  async (form: any, { rejectWithValue }) => {
    try {
      const resp = await AdminController.uploadAdminPicture(form);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmaciesForPharmacyAdmin = createAsyncThunk(
  adminActions.getPharmaciesForPharmacyAdmin,
  async (params: IGetPharmaciesForPharmacyAdminParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmaciesForPharmacyAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPharmaciesForPharmacyAdminPagination = createAsyncThunk(
  adminActions.getPharmaciesForPharmacyAdminPagination,
  async (params: IGetPharmaciesForPharmacyAdminParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getPharmaciesForPharmacyAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deletePharmacyAdmin = createAsyncThunk(
  adminActions.deletePharmacyAdmin,
  async (params: IDeletePharmacyAdminParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.deletePharmacyAdmin(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appointExistingPharmacy = createAsyncThunk(
  adminActions.appointExistingPharmacy,
  async (params: IAppointPharmacyParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.appointExistingPharmacy(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAppointedPharmaciesList = createAsyncThunk(
  adminActions.appointedPharmaciesList,
  async (params: IAdminPharmacyListBaseParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.appointedPharmaciesList(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const watchlists = createAsyncThunk(
  adminActions.watchlists,
  async (pharmacistId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.watchlists(pharmacistId);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastGroupSelectedUsers = createAsyncThunk(
  adminActions.getBroadcastGroupSelectedUsers,
  async (params: IBroadcastGroupParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.broadcastGroupSelectedUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBroadcastGroupSelectedUsersPagination = createAsyncThunk(
  adminActions.getBroadcastGroupSelectedUsersPagination,
  async (params: IBroadcastGroupParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.broadcastGroupSelectedUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminCalendarFilters = createAsyncThunk(
  adminActions.getAdminCalendarFilters,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.calendarFilters();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAdminPharmacistVacations = createAsyncThunk(
  adminActions.getAdminPharmacistVacations,
  async (id: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.getAdminPharmacistVacations(id);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPreSubmitVacations = createAsyncThunk(
  adminActions.adminPreSubmitVacations,
  async (params: IPreSubmitVacationsParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPreSubmitVacations(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPharmacistVacations = createAsyncThunk(
  adminActions.adminPharmacistVacations,
  async (params: IVacationsParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPharmacistVacations(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteAdminPharmacistVacations = createAsyncThunk(
  adminActions.deleteAdminPharmacistVacations,
  async (
    params: IAdminPharmacistVacationsDeleteParams,
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.deleteAdminPharmacistVacations(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminPreSubmitAvailabilities = createAsyncThunk(
  adminActions.adminPreSubmitAvailabilities,
  async (params: IAdminCreateAvailabilityApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminPreSubmitAvailabilities(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const preSubmitDeleteAvailabilities = createAsyncThunk(
  adminActions.preSubmitDeleteAvailabilities,
  async (availabilityId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.preSubmitDeleteAvailabilities(
        availabilityId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getActiveUserStatistics = createAsyncThunk(
  adminActions.activeUsersStatistics,
  async (params: IAdminStatisticsParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.activeUsersStatistics(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminUploadPharmacyAdminPicture = createAsyncThunk(
  adminActions.adminUploadPharmacyAdminPicture,
  async (
    {
      pharmacyAdminId,
      form,
    }: { pharmacyAdminId: string; form: FormData | null },
    { rejectWithValue }
  ) => {
    try {
      const resp = await AdminController.adminUploadPharmacyAdminPicture(
        pharmacyAdminId,
        form
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCancellationStatistics = createAsyncThunk(
  adminActions.cancellationStatistics,
  async (params: IAdminStatisticsParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.cancellationStatistics(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getFinancialStatistics = createAsyncThunk(
  adminActions.financialStatistics,
  async (params: IAdminStatisticsParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.financialStatistics(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getWorkshiftStatistics = createAsyncThunk(
  adminActions.workshiftStatistics,
  async (params: IAdminStatisticsParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.workshiftStatistics(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDailyStats = createAsyncThunk(
  adminActions.dailyStatistics,
  async (_, { rejectWithValue }) => {
    try {
      const resp = await AdminController.dailyStats();
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetPharmacyAdminPicture = createAsyncThunk(
  adminActions.adminGetPharmacyAdminPicture,
  async (pharmacyAdminId: string, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminGetPharmacyAdminPicture(
        pharmacyAdminId
      );
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const adminGetAllClients = createAsyncThunk(
  adminActions.adminGetAllClients,
  async (params: IAllClientApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.allClientUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue;
    }
  }
);

export const adminGetAllClientsPagination = createAsyncThunk(
  adminActions.adminGetAllClientsPagination,
  async (params: IAllClientApiParams, { rejectWithValue }) => {
    try {
      const resp = await AdminController.allClientUsers(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue;
    }
  }
);

export const adminResendEmail = createAsyncThunk(
  adminActions.adminResendEmail,
  async (params: IAdminResendEmail, { rejectWithValue }) => {
    try {
      const resp = await AdminController.adminResendEmail(params);
      return statusDispatch(resp, rejectWithValue);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setActiveUsersSegment = createAction<TypeOfUser>(
  adminActions.setActiveUsersSegment
);

export const adminResetValue = createAction<Array<string>>(
  adminActions.resetValue
);

export const persistIncompatibleMatchesSearch =
  createAction<IAdminIncompatibleMatchesSearch>(
    adminActions.incompatibleMatchesSearch
  );
