import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import {
  adminActions,
  pharmacyWorkshiftsList,
  successSelector,
} from '@pharmaplan/common';

import DynamicTable from '../../../DynamicTable';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useListOfWorkshifts from '../../../../hooks/Admin/PharmacyList/useListOfWorkshifts';
import strings from '../../../../localization';
import { Constants } from '../../../../helpers/Constants';

import EmptyIncompatibleMatches from '../../IncompatibleMatches/EmptyIncompatibleMatches';
import ListOfWorkshiftsHeader from './ListOfWorkshiftsHeader';

const successAction = adminActions.getPharmacyWorkshiftsList;

const ListOfWorkshifts = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const workshifts = useAppSelector(pharmacyWorkshiftsList);
  const loadSuccess = useAppSelector((loadingState) =>
    successSelector([successAction], loadingState));

  const pharmacyId = state ?? {};
  const { data } = workshifts ?? {};

  const {
    table,
    page,
    totalCount,
    handlePagination,
    setSelectedTab,
    selectedTab,
    formik,
    selectAll,
    selectAllFunc,
    handleWorkshiftDelete,
    selectableData,
  } = useListOfWorkshifts(pharmacyId);

  const noData = data.length === 0;
  const emptyContainerComponent = noData ? (
    <EmptyIncompatibleMatches message={strings.noDataAvailable} />
  ) : null;

  const backFn = () => {
    navigate(-1);
  };

  return (
    <DynamicTable
      table={table}
      loadSuccess={loadSuccess}
      totalCount={totalCount}
      page={page}
      showHeader
      showBackButton
      handlePagination={handlePagination}
      backFunction={backFn}
      emptyContainerComponent={emptyContainerComponent}
      customHeader={(
        <ListOfWorkshiftsHeader
          setSelected={setSelectedTab}
          selectedTab={selectedTab}
          formik={formik}
          selectAll={selectAll}
          handleSelectAll={selectAllFunc}
          handleDelete={handleWorkshiftDelete}
          selectableData={selectableData}
        />
      )}
    />
  );
};

export default ListOfWorkshifts;
