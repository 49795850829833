export default {
  login: 'Connexion',
  loading: 'Chargement',
  password: 'Mot de passe',
  confirmPassword: 'Confirmer le mot de passe',
  email: 'Email',
  home: 'Accueil',
  homeMessage: 'Bienvenue',
  profile: 'Profil',
  role: 'Rôle',
  confirm: 'Confirmer',
  logout: 'Déconnexion',
  clear: 'Effacer',
  apply: 'Appliquer',
  totalHrs: '{0} Hrs',
  availability: 'Disponibilité',
  availabilities: 'Disponibilités',
  pharmagap: 'PharmaGap',
  requested: 'Demandé',
  search: 'Rechercher',
  addAvailability: 'Ajouter une disponibilité',
  booked: 'Réservé',
  legend: 'Légende',
  summary: 'Sommaire',
  workshiftType: 'Type de quart de travail',
  posted: 'Affiché',
  workshift: 'Quart de travail',
  workshifts: 'Quart de travail',
  pageWorkshift: 'Quart(s) de travail',
  orContinueWith: 'ou continuer avec',
  orSignUpwith: "Ou s'inscrire avec",
  alreadyMember: 'Déjà membre? ',
  noAccount: 'Pas de compte ?',
  signup: "S'inscrire",
  forgetPassword: 'Mot de passe oublié ?',
  uploadYour: 'Téléchargez votre {0}',
  photo: 'photo',
  recurrence: 'Récurrence',
  resume: 'CV',
  general: 'Général',
  professionalInfo: 'Renseignements',
  software: 'Logiciels',
  softwareSingular: 'Logiciel',
  contact: 'Contact',
  languages: 'Langues',
  language: 'Langue',
  urgentCall: 'PharmaPlan',
  pharmaplan: 'Pharmaplan',
  help: 'Aide',
  changePassword: 'Changer le mot de passe',
  pharmacyInfo: 'Info. Pharmacie',
  primary: 'Primaire',
  secondary: 'Secondaire',
  accounting: 'Comptabilité',
  account: 'Compte',
  contacts: 'Contacts',
  settings: 'Paramètres',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  emailValid: 'Email doit être valide',
  passwordMin: 'Mot de passe {0} caractères min',
  graduationYearValidation: 'Année de diplôme doit être entre 1970 - 2020',
  preferredLanguage: 'Langue préférée',
  myGeographicAvailability: 'Mes préférences géographiques',
  save: 'Enregister',
  areYouIncorporated: 'Êtes-vous incorporé?',
  licenseNo: 'Numéro de licence',
  pharmacistCapacity: 'Capacité du pharmacien',
  yearLicensed: "Année d'obtention de la licence",
  yearsOfExperience: "Années d'expérience",
  englishLevel: "Niveau d'anglais",
  frenchLevel: 'Niveau de français',
  otherLanguage: 'Autre langues',
  address: 'Adresse',
  city: 'Ville',
  province: 'Province',
  postalCode: 'Code postal',
  homePhone: 'Téléphone',
  mobile: 'Mobile',
  preferredWorkingDays: 'Jours ouvrables préférés',
  preferredReplacementDuration: 'Durée de remplacement préférée',
  availabilityReminder: 'M’inscrire aux rappels de disponibilités',
  agree: 'Accepter',
  name: 'Nom',
  phone: 'Téléphone',
  phoneExt: 'Poste',
  oldPassword: 'Ancien mot de passe',
  newPassword: 'Nouveau mot de passe',
  volume: 'Débit',
  technicians: 'Techniciens',
  technician: 'Technicien',
  pharmacists: 'Pharmaciens',
  pharmacistsLower: 'pharmaciens',
  weekdays: 'Jours de la semaine',
  weekday: 'Jours de la semaine',
  weekdaySpace: 'En semaine (lun.-ven.)',
  weekends: 'Fin de la semaine',
  administrator: 'Administrateur',
  operations: 'Opérations',
  loginEmail: 'Courriel de connexion',
  ownersName: 'Nom du propriétaire',
  bannerNumber: 'Numéro de la bannière',
  preferences: 'Préférences',
  new: 'Nouveau',
  beginner: 'Débutant',
  good: 'Bien',
  expert: 'Expert',
  primaryContact: 'Contact Principal',
  secondaryContact: 'Contact Secondaire',
  accountingContact: 'Contact comptabilité',
  emailConfirmationsSent:
    'Les confirmations par email sont envoyées à ce contact uniquement',
  youSureLogout: 'Êtes-vous sûr de vouloir vous déconnecter?',
  yesLogout: 'Oui, Déconnexion',
  signout: 'Déconnexion',
  requestBooking: 'Demande de réservation',
  postedWorkshift: '{0} quart de travail posté',
  booking: 'Réservation',
  availableWorkshifts: 'Les remplacements disponibles',
  availableWorkshiftsMobile: 'Les remplacements dispos.',
  myAvailabilities: 'Mes disponibilités',
  dashboardAvailability: 'Mes dispos',
  bookingReport: 'Rapport de réservation',
  requestedWorkshifts: 'Les réservations demandés',
  workshiftReport: 'Les remplacements disponsibles',
  workshiftReportMobile: 'Les remplacements dispos.',
  postedWorkshiftReport: 'Quarts de travail affichés',
  filterBy: 'Filtrer par',
  banner: 'Bannière',
  pharmacyName: 'Nom de la Pharmacie',
  pharmacistName: 'Nom du Pharmacien',
  code: 'Code',
  never: 'Jamais',
  daily: 'Quotidienne',
  remove: 'Supprimer',
  everyWeekday: 'En semaine (lun-ven)',
  everyWeekdaySpecified: 'En semaine (lun-ven)',
  everyWeek: 'Hebdomadaire',
  everyMonth: 'Mensuelle',
  everyYear: 'Chaque Année',
  weekly: 'Hebdomadaire',
  monthly: 'Mensuelle',
  yearly: 'Annuel',
  selectTime: "Choisir l'heure",
  time: 'Heure',
  date: 'Date',
  setFrequency: 'Définir la fréquence',
  setEndDate: 'Définir la date de fin',
  action: 'Action',
  pending: 'En attente',
  notAssigned: 'Non assigné',
  selectedDate: 'Date sélectionnée',
  create: 'Créer',
  cancelled: 'Annulé',
  cancel: 'Annuler',
  status: 'Statut',
  addWorkshifts: 'Ajouter des quarts de travail',
  customEndDate: 'Date de fin personnalisée',
  updateWorkshift: 'Réactualiser le quart de travail',
  deleteWorkshift: 'Supprimer le quart de travail',
  replacements: 'Remplacements',
  myReplacements: 'Mes Remplaçants',
  myReplacementPharmacies: 'Mes pharmacies de remplacement',
  allDay: 'Toute la Journée',
  notes: 'Notes',
  send: 'Envoyer',
  noWorkshifts: 'Pas de quarts de travail!',
  day: 'Jour',
  update: 'Réactualiser',
  updateAvailability: 'Réactualiser la disponibilité',
  deleteAvailability: 'Supprimer la disponibilité',
  resetPassword: 'Réinitialiser le mot de passe',
  resetPassDesc:
    'Votre adresse e-mail que vous utilisez pour vous connecter à PharmaPlan',
  createNewPassword: 'Créer un nouveau mot de passe',
  typeReplacement: 'Type de Remplacement',
  createNewPasswordDesc:
    'Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés.',
  checkYourEmail: 'Vérifiez votre email',
  checkYourEmailDesc:
    'Nous vous avons envoyé des instructions de récupération de mot de passe à votre adresse e-mail.',
  openEmailApp: "Ouvrir l'application de messagerie",
  minimumHourlyRate: 'Taux horaire minimum',
  enterHourlyRate: 'Entrez votre taux horaire',
  searchAvailability: 'Recherche de quart de travail',
  history: 'Historique',
  exportAsAPdf: 'Exporter en PDF',
  cancelBookedWorkshift: 'Annuler le quart de travail réservé',
  cancelWorkshift: 'Annuler le quart de travail demandé',
  created: 'Créé',
  updated: 'Actualisé',
  myUserNotes: 'Mes notes de {0}',
  myUserHistory: 'Mon historique de {0}',
  newNote: 'Nouvelle Note',
  typeANewNote: 'Tapez une nouvelle Note',
  selectDate: 'Sélectionnez une Date',
  createNewNote: 'Créer une nouvelle Note',
  pharmacist: 'Pharmacien',
  workshiftDetails: 'Détails du quart de travail',
  pleaseEnableLocation: 'Veuillez activer votre emplacement',
  close: 'Fermer',
  repeat: 'Répéter',
  everyDay: 'Tous les jours',
  onDay: 'le jour',
  onThe: 'le',
  first: 'Premier',
  second: 'Deuxième',
  third: 'Troisième',
  fourth: 'Quatrième',
  typeADay: 'Entrez un jour',
  selectMonth: 'Sélectionnez un mois',
  selectWeekNumber: 'Sélectionner le numéro de semaine',
  selectWeekDay: 'Sélectionner le jour de la semaine',
  weekDaysAndMonths: 'Jours de la semaine et mois',
  recurrenceName: 'récurrence',
  repeatEvery: 'Répéter tous les',
  pickFromPhotos: 'Choisir à partir de photos',
  takeAPhoto: 'Prendre une photo',
  gallery: 'Galerie',
  camera: 'Caméra',
  english: 'Anglais',
  french: 'Français',
  startTime: " L'heure de début ",
  endTime: "L'heure de fin",
  manageWorkshifts: 'Gérer les Quart de travail',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  saveGeneralProfile: 'Enregistrer le profil général',
  saveProInfoProfile: 'Enregistrer les informations professionnelles',
  saveContact: 'enregistrer les Contacts',
  savedSuccessfully: 'Enregistré avec succès',
  invalidEmail: 'Email ou mot de passe invalide !',
  done: 'Terminé',
  successMessage: 'Vos données de profil ont été enregistrées',
  errorMessage:
    'Une erreur est survenue, nous vous tiendrons informé plus tard !',
  ok: 'OK',
  error: 'Erreur!',
  rememberMe: 'Se souvenir de moi',
  area: 'Région',
  requiredField: '* Obligatoire',
  paswordsnotMatch: 'Les mots de passe ne correspondent pas !',
  passwordsMustMatch: 'Les mots de passe doivent correspondre',
  minimumSixCharacters: '{0} doit comporter au moins 6 caractères',
  cancelBooking: 'Annuler la réservation',
  cancelRequest: 'Annuler la demande',
  editAvailability: 'Modifier la disponibilité',
  editEvent: 'Modifier la {0}',
  deleteNote: 'Supprimer la note',
  updateNote: 'Modifier la note',
  weekVolumes: 'Débit en semaine',
  weekEndVolumes: 'Débit en fin de semaine',
  region: 'Région',
  userDashboard: 'Tableau de bord de {0}',
  registrationInfo: "Informations d'inscription",
  submit: 'Soumettre',
  alphaOnly: 'alpha uniquement',
  noSoftware: 'pas de logiciel',
  pharmacy: 'Pharmacie',
  thankyouRegistration:
    "Merci pour votre inscription!\nL'activation de votre compte est en attente",
  profileMessage: 'Message de profil',
  languageValidation: 'Validation de la langue',
  phoneValidation: 'Validation du téléphone',
  wrongFormat: 'Format incorrect',
  sunday: 'Dimanche',
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  updatPastAvailabilityError: 'Disponibilités passées non modifiables!',
  loginAsAnotherUser: 'Connexion autre utilisateur',
  loginAsUsername: 'Connexion {0}',
  provinceValidation: 'Province seulement 2 caractères',
  postalCodeValidation: 'Format invalide',
  permissionDenied: 'Permission refusée',
  allowCamera: 'Autoriser caméra',
  weakLevel: 'Faible',
  goodLevel: 'Bon',
  fluentLevel: 'Excellent',
  pleaseSelectDate: "S'il vous plaît sélectionnez une date",
  showPharmacyLocation: 'Emplacement de la pharmacie',
  showDetails: 'Afficher les détails de la réservation',
  emergency: 'Urgence',
  allowance: 'Indemnité',
  travel: 'Voyage',
  meal: 'Repas',
  bookingPharmacyDetails: 'Détails de la réservation de la pharmacie',
  minCharacters:
    'Le mot de passe doit avoir 8 caractères avec une majuscule, un chiffre et un caractère spécial.',
  numberValidation: '{0} doit être un nombre',
  january: 'janvier',
  february: 'février',
  march: 'mars',
  april: 'avril',
  may: 'mai',
  june: 'juin',
  july: 'juillet',
  august: 'août',
  september: 'septembre',
  october: 'octobre',
  november: 'novembre',
  december: 'décembre',
  allRegions: 'Toutes les Régions',
  locationPermessionDenied:
    "Autorisation de localisation refusée par l'utilisateur !",
  positionUnavailable:
    'Les informations de localisation ne sont pas disponibles !',
  timeout:
    "Le délai de la demande de localisation de l'utilisateur est expiré !",
  activityNull: 'Une erreur inconnue est survenue !',
  noEventFound: 'Aucun événement trouvé!',
  pharmacyPhone: 'Numéro de téléphone de la pharmacie',
  pharmacyFax: 'Fax de la pharmacie',
  emergencyFax: "Fax d'urgence",
  primaryContactName: 'Nom du contact principal',
  primaryContactPhone: 'Numéro de téléphone principal.',
  contactPhoneExtension: 'Numéro de poste',
  primaryContactMobile: 'Mobile du contact principal',
  primaryContactEmail: 'Courriel du contact principal',
  secondaryContactName: 'Nom du contact secondaire',
  secondaryContactPhone: 'Numéro de téléphone du contact secondaire',
  secondaryContactMobile: 'Mobile du contact secondaire',
  secondaryContactEmail: 'Courriel du contact secondaire',
  accountingContactName: 'Nom du contact de la comptabilité',
  accountingContactPhone: 'Accounting Contact Phone',
  accountingContactMobile: 'Mobile du contact de la comptabilité',
  accountingContactEmail: 'Courriel du contact de la comptabilité',
  coordinates: 'Coordonnées',
  ownerCompanyName: 'Nom de la société',
  contactPreferences: 'Préférences de contact',
  emergencyPhone: "Téléphone d'urgence",
  fax: 'Fax',
  softwareFamiliarity: 'Familiarité avec le logiciel',
  pharmacistTotalRating: 'Note totale du pharmacien',
  reviews: 'Avis',
  noDataFound: 'Aucune donnée trouvée',
  requestCancellationBooking: "Demander l'annulation de la réservation",
  unfulfilled: 'Non-comblé',
  pharmacistDetails: 'Détails du pharmacien',
  pleaseSelectAction: 'Veuillez sélectionner une action pour continuer',
  updateSeries: 'Mettre à jour la série',
  updateOccurrence: "Mettre à jour l'occurrence",
  updatePastWorkshiftError:
    'Impossible de modifier les quarts de travail passés !',
  myReplacementUser: 'Mes {0} remplaçants',
  selectedContactPreference: 'Préférences de contact sélectionnées',
  pleaseSelectOne: '* Sélectionnez au moins une option',
  number: 'Numéro',
  onlySpace: 'Ce champ ne peut pas contenir uniquement des espaces blancs',
  unfamiliar: 'Inconnu',
  veryGood: 'Très bon',
  next: 'Suivant',
  checkMail: 'Vérifier le courrier',
  clickToUpload: 'Cliquez pour télécharger',
  or: 'ou',
  ourCommitment:
    'Notre engagement est de vous fournir une solution personnalisée à vos besoins.',
  dragAndDrop: 'Glisser et déposer',
  en: 'EN',
  on: 'Sur',
  plan: 'Plan',
  fr: 'FR',
  pharmaPlan: 'PharmaPlan',
  pharma: 'Pharma',
  availabilitesReports: 'Rapport des disponibilités',
  enterEmail: "Entrer l'adresse e-mail",
  enterCred: 'Entrer {0}',
  continueWith: 'Ou continuer avec',
  at: 'À',
  signIn: 'Se connecter',
  welcomeTo: 'Bienvenue à',
  findOutMore: 'En savoir plus',
  from: 'De',
  forgotPass: 'Mot de passe oublié?',
  professionalReplacement: "Besoin d'un remplacement professionnel?",
  to: 'À',
  monthlyOnDay: 'Mensuel le jour {0}',
  monthlyOnFourth: 'Mensuel le quatrième {0}',
  occursEvery: 'Se produit tous les',
  dayFeedbackMessage: 'Jour',
  daysFeedbackMessage: '{0} Jours',
  weekFeedbackMessage: 'Semaine sur {0}',
  weeksFeedbackMessage: '{0} semaines sur {1}',
  monthFeedbackMessage: 'mois {0}',
  monthsFeedbackMessage: '{0} mois {1}',
  yearFeedbackMessage: 'année {0}',
  yearsFeedbackMessage: '{0} ans {1}',
  imageUploadNote: 'Format PNG, JPEG ou SVG (taille maximale 800x400 px)',
  pdfUploadNote: 'Format PDF ou doxe (taille maximale 2 Mo)',
  bookingReports: 'Rapport des Réservations',
  bookingDetails: 'Détails de réservation',
  viewPharmacyLocation: "Voir l'emplacement de la pharmacie",
  viewPharmacyLocationMobile: "Voir I'emplacement",
  reportTitle: 'Rapports de {0}',
  available: 'Disponible',
  requestedCount: '{0} demandé',
  bookedCount: '{0} réservé',
  postedCount: '{0} affiché',
  customRecurrence: 'Récurrence personnalisée',
  replacementWorkshifTitle: 'Remplacement de travail le {0}',
  ends: 'Se termine',
  moreInfo: "Plus d'informations",
  recurrences: 'Récurrences',
  delete: 'Supprimer',
  allday: 'Toute la journée',
  hoursWorked: '{0} h',
  welcomeUser: 'Bienvenue, {0}!',
  fileUploadSuccess: 'Fichier téléchargé avec succès',
  manageYourInfo: 'Gérer vos informations',
  hours: 'Heures',
  dashboard: 'Tableau de bord',
  week: 'Semaine',
  month: 'Mois',
  year: 'Année',
  custom: 'Personnalisé',
  doNotRepeat: 'Ne pas répéter',
  myReplacment: 'Mon remplacement',
  reports: 'Rapports',
  createEvent: 'Crée {0}',
  createdEvent: 'Crée {0}',
  favourite: 'Favori',
  rowsPerPage: 'lignes par page',
  francais: 'Français',
  uploadYourPhoto: 'Téléchargez votre photo',
  uploadYourResume: 'Téléchargez votre CV',
  profissonalWork: 'Travail professionnel',
  profissonalInfo: 'Informations professionnelles',
  addressPhoneNo: 'ADRESSE ET NUMÉRO DE TÉLÉPHONE',
  start: 'Début',
  sun: 'Dimanche',
  mon: 'Lundi',
  tue: 'Mardi',
  wed: 'Mercredi',
  thurs: 'Jeudi',
  fri: 'Vendredi',
  sat: 'Samedi',
  hourlyRate: 'Taux horaire',
  hourlyRates: 'Taux horaires',
  zoomJar: 'ZoomJar Technology Inc.',
  rightsReserved: 'Tous droits réservés.',
  termsOfService: "Conditions d'utilisation",
  copyRight: 'Copyright© 2010-{0}',
  privacyPolicy: 'Règles de Confidentialité ',
  siteMap: 'Plan du site',
  resetDesc:
    'Votre adresse e-mail que vous utilisez pour vous connecter à PharmaPlan',
  passwordRecoveryMail:
    'Nous avons envoyé une instruction de récupération de mot de passe à votre adresse e-mail.',
  createNewPw: 'Créer un nouveau mot de passe',
  newPasswordDesc:
    'Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés.',
  backToLogin: 'Retour à la connexion',
  openYourEmail: 'Ouvrez votre e-mail',
  urgentNeed: 'Besoin urgent?',
  reset: 'Réinitialiser',
  profileSaved: 'Vos données de profil ont été enregistrées',
  myPharmacyHistory: "L'historique de ma pharmacie",
  pleaseEnterValidTime: 'Veuillez entrer une date valide',
  report: '{0} Report',
  pageHeaderTitle: 'PharmaPlan - {0}',
  myPharmacies: 'Mes pharmacies',
  yes: 'Oui',
  no: 'Non',
  accepted: 'Accepté',
  pleaseEnterValidDate: 'Veuillez entrer une date valide',
  pharmaciesServed: 'Pharmacies servies',
  totalReplacements: 'Remplacements totaux',
  totalHours: 'Total des heures',
  replacementType: 'Type de remplacement',
  picture: 'image',
  emailFr: 'Courriel',
  signupAs: "S'inscrire en tant que {0}",
  back: 'Retour',
  today: "Aujourd'hui",
  releaseDate: 'Date',
  version: 'Version',
  loginAs: 'Se connecter en tant que',
  searchCurrentAvailability: 'Affiner la recherche',
  yesCancel: 'Oui, Annuler',
  confirmation:
    'Êtes-vous sûr de vouloir annuler la réservation de ce créneau de travail ?',
  cancelBookingConfirmation: 'Annuler la demande de réservation',
  records: 'Records',
  singlePostedWorkshift: 'Créneau de travail publié',
  selectRole: 'Sélectionnez votre rôle',
  join: 'Adhérez à',
  pharmacyOwner: 'Pharmacie (Pharmacien Propriétaire)',
  minimumDate: 'Les dates passées ne sont pas autorisées',
  signupSuccessful: 'Votre inscription a été réussie !',
  enterOtp: 'Entrez le code à usage unique',
  verify: 'Vérifier',
  resendOtpIn: 'Renvoyer le code dans',
  resendOtp: 'Renvoyer le code',
  faq: 'FAQs',
  backGuide: 'Précédant',
  end: 'Fin',
  skip: 'Sauter',
  bellBtn:
    'L’icône «Notifications» affiche dans un ordre chronologique les événements qui se sont produits dans votre compte.',
  profileBtn:
    'La touche « Profil » vous permet de gérer vos renseignements personnelles et professionnelles, de modifier votre mot de passe, de mettre à jour les paramètres de votre compte et de consulter les FAQs.',
  dashboardBtn:
    'Le « Tableau de Bord » est votre interface principale qui affiche des informations mensuelles, hebdomadaires ou quotidiennes sur les quarts de travail.',
  availabilityBtn:
    'La touche « + » vous permet de gérer vos disponibilités ou vacances. Appuyez sur « + » pour publier une ou plusieurs journées récurrentes de disponibilité ou vacances sur le système.',
  availabilityWebBtn:
    'Le bouton « + » vous permet de gérer vos disponibilités ou vacances.  Cliquer sur « + » pour ajouter une ou plusieurs journées de disponibilité ou vacances sur le système',
  myReplacementsBtn:
    'La touche « Mes remplaçants » affiche les noms, no. de quarts et les heures qu’ils ont effectuées. Vous pouvez les évaluer, identifier les favoris et ajouter des notes pour chacun.',
  reportsBtn:
    'La touche « Rapports » vous donne accès à des rapports sur vos activités et vos performances.',
  datePicker:
    'Le «Sélecteur de date» vous permet de sélectionner un mois spécifique à afficher sur le tableau de bord.',
  monthView:
    'La « Vue mensuelle » vous montre les quarts de travail d’un mois particulier.',
  weekView:
    'La « Vue hebdomadaire » vous montre les quarts de travail d’une semaine particulière.',
  dayView:
    'La « Vue quotidienne » vous montre les quarts de travail d’un jour particulier.',
  searchBtn:
    'La touche « Recherche » vous permet de filtrer les quarts de travail dans la Vue actuelle.',
  legendGuide:
    "La « Légende » indique combien d'heures au total pour chaque type de quart de travail (avec des couleurs différentes). Vous pouvez décider quels types de quarts vous voulez voir dans le calendrier du tableau de bord en cochant ou décochant les cercles à côté d'eux.",
  mapView:
    'La « vue cartographique » vous permet de visualiser l’emplacement géographique des quarts disponibles, demandés et réservés. Vous pouvez revenir à la « vue calendrier » en cliquant l’icône «',
  todayBtn:
    'La touche « Aujourd’hui » vous ramène rapidement à la vue du mois, de la semaine ou du jour en cours.',
  profileBtnPharmacy:
    'La touche « Profil » vous permet de gérer les renseignements de votre pharmacie, de modifier votre mot de passe, de mettre à jour les paramètres de votre compte et de consulter les FAQs.',
  myWorkShifts:
    'La touche « Gérer mes demandes » vous permet de gérer vos demandes. Appuyez sur la touche « + » pour créer des demandes d’un jour ou récurrentes.',
  reportsBtnPharmacy:
    'La touche « Rapports » vous donne accès à des rapports sur vos activités.',
  filterBtn:
    'La touche « Filtre » vous permet de filtrer les quarts de travail basé sur leur type dans la Vue actuelle.',
  notificationIcon:
    '« L’icône « Notifications » affiche dans un ordre chronologique les événements qui se sont produits dans votre compte ».',
  pharmaciesServedGuide:
    'L’onglet « Pharmacies Servies » affiche les pharmacies desservies avec une option d’aperçu historique. Vous pouvez définir des favoris et ajouter des notes pour chacune.',
  watchlistIcon:
    'L’icône « Liste de surveillance » affiche des quarts que vous avez placés sur votre liste de surveillance pour des considérations futurs.',
  notificationIconPharmacist:
    'L’icône « Notifications » affiche dans un ordre chronologique les événements qui se sont produits dans votre compte.',
  legendGuidePharmacist:
    'La « Légende » affiche les types de quarts de travail codés par couleur, ainsi que les disponibilités et les vacances pour le mois en vue. Vous pouvez produire une liste en cliquant dessus.',

  tempAvailability: 'nouvelle disponibilité',
  confirmAvailabilityMessage:
    'Veuillez confirmer que vous avez créé ces jours {0} en tant que {1} avec votre horaire',
  rate: 'Taux',
  confirmEvent: 'Confirmer {0}',
  preferredTime: 'Système de temps préféré',
  twentyFourHours: 'Format 24 heures',
  twelveHours: 'Format 12 heures (AM/PM)',
  skipConfirmation: 'Ne plus afficher ce message',
  tooltipShowDetails: 'Plus d’info',
  termsAndConditions: "conditions d'utilisation",
  termsAndConditionsHeader: 'Conditions de service de PharmaPlan',
  agreeOn: 'J’accepte les ',
  termsAndConditionsValidation:
    '* Vous devez accepter les Conditions d’utilisation',

  multiple: 'Multiple',
  userLocation: 'Emplacement de {0}',
  pharmacyLocation: 'Emplacement de la pharmacie',
  pharmacistLocation: 'Emplacement du pharmacien',
  myLocationOnMap: 'Ma position sur la carte',
  mapButtonLabel: 'Choisir sur la carte',
  mapSelectLabel: 'Sélectionner...',
  pickYourLocationOnMap: 'Choisissez votre emplacement sur la carte',
  accept: 'Accepter',
  reject: 'Rejeter',
  bookingRequests: 'Demandes de réservation',
  bookingRequestsNum: 'Demandes de réservation ({0})',
  distance: 'Distance',
  numAway: '{0} km de distance',
  requestedIn: 'Demandé à : {0}',
  requestedOn: 'Demandé le : {0}',
  workshiftDate: 'Quart de travail : {0}',
  counterOffer: 'Contre-offre',
  billDetails: 'Détails de la facture',
  finalBillDetails: 'Détails du contrat',
  softwareExpertise: 'Expertise en logiciel',
  viewMore: 'Voir plus',
  viewLess: 'Voir moins',
  total: 'Total',
  contactDetails: 'Coordonnées',
  sortBy: 'Trier par',
  rating: 'Évaluation',
  requestedDate: 'Date demandée',
  totalCost: 'Coût total',
  covered: 'Couvert',
  notCovered: 'Non couvert',
  fixedAmount: 'Montant fixe',
  calculate: 'Calculer',
  rateAndAllowances: 'Taux et indemnités',
  reportAnIssue: 'Signaler un problème',
  describeIssue: 'Décrire le problème',
  issueRefNo: 'Numéro de référence du problème :',
  issueResp: 'Merci ! Nous vous répondrons sous peu.',
  submitSuccessfully: 'Soumis avec succès',
  submitIssue: 'Soumettre un problème',
  selectIssue: 'Sélectionner un problème',
  issueDescription: 'Description du problème',
  ratePharmacist: 'Évaluer le pharmacien',
  ratingPharmacist: 'Évaluer le pharmacien',
  endsOn: 'Se termine le',
  enterAhead: 'Veuillez entrer une heure supérieure de {0} heures à partir de',

  currentTimeValidation:
    "Veuillez entrer une heure supérieure à l'heure actuelle",
  pharmacistRating: 'Évaluation du pharmacien',
  bookingCost: 'Valeur du contrat',
  accomodation: 'Hébergement',
  pharmaPlanFees: 'Frais de PharmaGap',
  pharmacyOperation: 'Fonctionnement de la pharmacie',
  submitCounterOffer: 'Soumettre une contre-offre ?',
  viewPostedWorkshifts: 'Autres demandes de cette pharmacie ({0})',
  viewPostedWorkshiftsMobile: 'Autres de cette pharmacie ({0})',
  workshiftPosted: 'Quart de travail affiché',
  travelAllowances: 'Indemnités de déplacement',
  accommodationAllowances: "Indemnités d'hébergement",
  mealAllowances: 'Indemnités de repas',
  emergencyServiceFees: "Frais de service d'urgence",
  optionalEmergencyFees: "Frais de service d'urgence (facultatif)",
  forEveryKmAbove: 'pour chaque 1 km au-dessus de ',
  noRequests: 'Aucune demande',
  numberRequests: '{0} demandes',
  numberRequest: '{0} demande',
  otherRequests: '+{0} autres demandes',
  notRecurring: 'Non récurrent',
  town: 'Ville',
  seeMore: 'Voir plus',
  workshiftRequested: 'Quart de travail demandé',
  discardChanges: 'Ignorer les modifications',
  dayShift: 'quart de jour',
  nightShift: 'quart de nuit',
  pharmaplanCharges: '*PharmaPlan facture {0}% du coût de la réservation',
  applicableTaxes: "*Le montant ci-dessus n'inclut pas les taxes applicables",
  requestedAgo: 'Demandé il y a {0} {1}',
  smallHours: 'heures',
  smallDays: 'jours',
  enterFixedAmount: 'Entrer un montant fixe',
  calculated: 'Calculé',
  comment: 'Commentaire',
  confirmationPending: 'Confirmation en attente',
  viewRating: "Voir l'évaluation",
  createWorkshift: 'Créer un quart de travail',
  minutes: 'minutes',
  goodNews: 'Bonne nouvelle ! Vous avez été choisi(e)!',
  requestWillBeAutoRejected:
    "La demande sera automatiquement rejetée après expiration du délai d'attente",
  confirmIn: 'Confirmer dans {0} heures {1} minutes',
  confirmBefore: 'Confirmer avant {0}',
  congratulations: 'Félicitations !',
  confirmedBookings: 'La réservation est confirmée.',
  pendingBooking: 'Réservation en attente',
  bookingPendingForConfirmation:
    'La réservation est en attente de confirmation du pharmacien.',
  workshiftBooked: 'Quart de travail réservé',
  offer: 'offre',
  enterAmount: 'Entrer le montant',
  rejectionConfirm: 'Êtes-vous sûr(e) de vouloir rejeter cette réservation ?',
  rejectBooking: 'Rejeter la réservation',
  myPharmacistRating: 'Mon évaluation du pharmacien',
  pleaseEnterLessThan: 'Veuillez entrer un montant inférieur à {0}',
  requestsNum: 'Demandes ({0})',
  before: 'Avant',
  updateTiming: "Mettre à jour l'horaire",
  saveChanges: 'Sauvegarder les modifications',
  availableWorkshift: 'Quart de travail disponible',
  requestedWorkshift: 'Quart de travail demandé',
  bookedWorkshift: 'Quart de travail réservé',
  bookedWorkshifts: 'Quart de travail réservé',
  counterOfferSubmitted: 'Votre contre-offre a été soumise',
  workshiftHasAConfirmed:
    'Le quart de travail a une réservation confirmée. Êtes-vous sûr(e) de vouloir mettre à jour ?',
  proceed: 'Continuer',
  updatesArePending:
    'Des mises à jour sont en attente de confirmation par le pharmacien réservé.',
  updateWillBeAutoCancelled:
    "La mise à jour sera automatiquement annulée après expiration du délai d'attente.",
  updateWillBeAutoRejected:
    "Les mises à jour seront automatiquement rejetées après expiration du délai d'attente.",
  approveUpdates: 'Approuver les mises à jour',
  declineUpdates: 'Refuser les mises à jour',
  pharmacyUpdatedWorking: 'Mises à jour heures de la pharmacie',
  cancelBookingMightIncur:
    'Annuler la réservation pourrait entraîner des frais selon les',
  tAndC: 'T&C',
  rejectCounterOffer: "Rejeter l'offre de contre-proposition ?",
  acceptCounterOffer: "Accepter l'offre de contre-proposition ?",
  confirmDeleteWorkshift:
    'Êtes-vous sûr(e) de vouloir supprimere ce quart de travail ?',
  youHaveEnteredCancelation:
    "Vous êtes dans la période d'annulation impossible ! Veuillez contacter PharmaPlan",
  areYouSureRejectCounter:
    'Êtes-vous sûr(e) de vouloir rejeter \n cette contre-proposition ?',
  areYouSureAcceptCounter:
    'Êtes-vous sûr(e) de vouloir accepter \n cette contre-proposition ?',
  shiftDetails: 'Détails de quart',
  times: 'Heures',
  mustBeNumber: 'doit être un nombre',
  submitCounterOfferDef: 'Soumettre une contre-offre',

  volumeValidation: '(par exemple, 1234 ou 12-34)',
  backUpdatePrompt: 'Veuillez mettre à jour les informations opérationnelles',
  updateOperationMessage:
    "Pour continuer avec l'application Pharmaplan, veuillez mettre à jour les informations opérationnelles.",
  pleaseEnterMoreThan: 'Veuillez entrer plus que {0}',
  youCannotUpdatePastBookingTiming:
    'Vous ne pouvez pas modifier les horaires de réservation passés',
  otpSent: 'Un OTP a été envoyé à votre adresse e-mail enregistrée',
  updateOperation: 'Mise à jour des opérations',
  ratingSuccessful: "L'évaluation du pharmacien a été soumise avec succès !",
  requestSuccess: 'La demande de quart de travail a été soumise avec succès.',
  continueCreation:
    'Voulez-vous créer davantage de {0} ? Si non, vous serez redirigé vers le tableau de bord.',
  createMore: 'Créer davantage',
  softwareWarning:
    'Connaissance insuffisante de {0} ({1}%). Sinon, SVP mettre votre profil à jour.',

  postalCodeHint: 'eg: A1A1A1',
  bookingCanceled: 'Votre réservation a été annulée.',
  registerBiometric:
    'Voulez-vous activer vos données biométriques pour une expérience de connexion plus sécurisée ?',
  biometric: 'Enregistrement biométrique',
  enableBiometric: 'Activer la biométrie',
  validateBiometric: 'Valider les données biométriques',
  registrationSuccessfull:
    "Votre processus d'enregistrement biométrique a été terminé avec succès.",
  myReplacementPharmacists: 'Mes Remplaçants',
  replayWalkthrough: 'Répéter l’aide-mémoire',
  pleaseEnableBiometric: 'Veuillez activer la biométrie sur votre appareil.',
  expiredError: "Le quart de travail que vous tentez d'accéder a expiré.",
  noDataAvailable: 'Aucune donnée disponible!',
  preferredDistanceRadius: 'Rayon de distance préféré (km)',
  markAsRead: 'Marquer tous comme lus',
  deleteMyAccount: 'Supprimer mon compte',
  confirmBy: 'Confirmer par {0}',
  preferredDistanceRange: 'Rayon de distance préféré',
  contractDetails: 'Détails du contrat',
  pharmacistContractValue: 'Valeur du contrat de pharmacien',
  contractValue: 'Valeur du contrat',

  read: 'Read',
  notifications: 'Notifications',

  pharmaciesNearby: 'Pharmacies à proximité',
  pleaseEnableAccessLocation: "Veuillez activer l' accès à votre emplacement",
  deleteAccountConfirm: 'Êtes-vous sûr de vouloir supprimer le compte ?',
  aboutUs: 'À propos',
  themeSettings: 'Paramètres du Thème',
  light: 'Clair',
  dark: 'Sombre',
  auto: 'Automatique',
  themeChanged: 'Vos paramètres de thème ont été enregistrés.',
  deleteConfirmationPharmacy:
    'Toutes vos prochaines publications de quart de travail/ demandes seront supprimées. Êtes-vous sûr de vouloir poursuivre la suppression?',
  deleteConfirmationPharmacist:
    'Toutes vos disponibilités/ demandes futures seront supprimées. Êtes-vous sûr de vouloir poursuivre la suppression?',

  usesCookie: 'Notre site web utilise des cookies',
  cookieConsent:
    'Nous utilisons uniquement les cookies essentiels pour optimiser votre expérience sur la plateforme. Soyez assuré(e) que nous ne partageons aucune donnée avec des plateformes tierces à des fins de vente ou de marketing. Votre vie privée est notre priorité. En savoir plus dans notre',
  cookiePolicy: 'Politique en matière de cookies',
  updateNow: 'Mettre à jour',
  updateRequired: 'Mise à jour requise !',
  acceptCookies: 'Accepter les cookies',
  incomeReport: 'Rapport de revenus',
  totalRevenue: 'Revenu total',
  totalWorkshifts: 'Quarts de travail totaux',
  bookedWorkshiftDetails: 'Détails des quarts de travail réservés',
  sendCommunicationsCopyTo: 'Envoyer des copies de messages à',
  tax: 'Tx',
  workshiftWithDate: 'Date du quart de travail: {0}',
  backTo: 'Retour à',
  viewDetails: 'Voir les détails',
  pharmacies: 'Pharmacies',
  workshiftDateText: 'Date du quart de travail',
  pleaseTryAgain: "S'il vous plaît, essayez de nouveau",
  fullScreen: 'Full Screen',
  removeFavorite: 'Retirer des favoris',
  addFavorite: 'Ajouter aux favoris',
  areYouSureRequestWorkshift:
    'Êtes-vous sûr de vouloir demander ce quart de travail?',
  timeColon: 'Heure: {0}',
  availableWorkshiftFormatted: 'Les remplacements disponibles: {0}',
  location: 'Emplacement',
  configuration: 'Configuration',
  admin: 'Administrateurs',
  logs: 'Logs',
  expressBooking: 'Réservation express',
  pendingActivation: 'Activations en attente',
  pendingCancellation: 'Les annulations en attente',
  administratorList: 'Liste des administrateurs',
  activeUsers: 'Utilisateurs actifs',
  adminDashboard: "Tableau de bord d'administrateur",
  createdFormat: 'Créé {0}',
  hourlyRateSuffix: '${0}/Hr',
  selectedDat: 'Sélectionnez une Date',
  requestedWorkshiftsFormatted: 'Les réservations demandés: {0}',
  bookedWorkshiftsFormatted: 'Quart de travail réservé: {0}',
  selectUser: 'Sélectionnez {0}',
  pharmacistCapacities: 'Capacités des pharmaciens',
  softwareList: 'Liste de logiciels',
  pharmacyChains: 'Chaînes de pharmacies',
  adminPharmaplan: 'Administrateur PharmaPlan',
  pharmacyAdministrators: 'Administrateurs de pharmacie',
  incompatibleMatch: 'Correspondance incompatible',
  broadcastNotifications: 'Notifications diffusées',
  incompatible: 'Incompatible',
  compatible: 'Compatible',
  markFormat: 'Marquer comme {0}',
  compatibility: 'Compatibilité',
  areYouSureMarkFormat:
    'Êtes-vous sûr de vouloir marquer le pharmacien comme {0}?',
  banners: 'Bannières',
  globalSettings: 'Paramètres globaux',
  incompatibility: 'Incompatibilité',

  pharmacistsList: 'Liste des pharmaciens',
  pharmacyList: 'Liste des pharmacies',
  activityLogs: "Journaux d'activité",
  pharmacistsCapacities: 'Capacités des pharmaciens',
  adminPharmaPlan: 'Admin PharmaPlan',
  postedWorkshiftsReport: 'Rapport des quarts de travail publiés',
  requestedReport: 'Rapport demandé',
  availabilityReport: 'Rapport de disponibilité',
  editWorkshift: 'Modifier le quart de travail',
  pharmacyChain: 'Chaîne de pharmacies',
  settingsInfo: 'Informations sur les paramètres',
  noOfDaysToRatePharmacist: 'Nombre de jours pour évaluer le pharmacien',
  representativePhone: 'Téléphone du représentant',
  salesForceSecurityToken: 'Jeton de sécurité Salesforce',
  showAllBookingDetails: 'Afficher tous les détails de réservation',
  minHoursWorkshift: 'Durée minimale du quart de travail',
  representativeExtPhone: 'Téléphone avec extension du représentant',
  urlOfSalesForce: 'URL de Salesforce',
  representativeName: 'Nom du représentant',
  salesForceUsername: "Nom d'utilisateur Salesforce",
  sendAllEmailsBCC: "Envoyer tous les e-mails en CCI à l'administrateur",
  representativeEmail: 'E-mail du représentant',
  salesForcePWD: 'Mot de passe Salesforce',
  integrationSalesForce: 'Intégration Salesforce',
  matchingAvailabilities: 'Correspondances des disponibilités',
  contactName: 'Nom du contact',
  edit: 'Modifier',
  generalDetails: 'Détails généraux',
  approveCancellation: "Approuver l'annulation",
  sendEmailNotification: 'Envoyer une notification par courriel à {0}',
  contactNumber: 'Numéro de contact',
  approve: 'Approuver',
  ignore: 'Ignorer',
  bookingDate: 'Date de réservation',
  ignoreWorkshift: 'Ignorer le quart de travail',
  sendEmailNotificationPharmacist:
    'Envoyer une notification par courriel au pharmacien',
  approveBooking: 'Approuver la réservation',
  notesForSalesOrder: 'Notes pour la commande',
  pharmacistHourlyRate: 'Taux horaire du pharmacien',
  pharmacyHourlyRate: 'Taux horaire de la pharmacie',
  book: 'Réserver',
  matchingAvailabilitiesOfWorkshift:
    'Disponibilités correspondantes du quart de travail',
  requestCancellation: "Demander l'annulation",
  reason: 'Raison',
  showOtherAvailabilities: "Afficher d'autres disponibilités",
  hideOtherAvailabilities: "Masquer d'autres disponibilités",
  otherAvailabilities: 'Autres disponibilités',
  noMatchingAvailabilitiesToShow:
    'Aucune disponibilité correspondante à afficher.',
  noOtherAvailabilitiesToShow: 'Aucune autre disponibilité à afficher.',
  userLabSchedulingExt: 'Extensions de planification du labo utilisateur ?',
  pharmaplanID: 'ID Pharmaplan',
  shortPharmacyName: 'Nom abrégé de la pharmacie',
  other: 'Autre',
  operationalInfo: 'Informations opérationnelles',
  graduationYear: 'Année de diplomation',
  professionalInformation: 'Informations professionnelles',
  licenseNumber: 'Numéro de licence',
  pharmacistProfileDetails: 'Détails du profil du pharmacien',
  pharmacyProfileDetails: 'Détails du profil de la pharmacie',
  saveBooking: 'Enregistrer la réservation',
  incompatibleWarning:
    'Ce pharmacien figure dans la liste des incompatibles. Êtes-vous sûr de vouloir continuer ?',
  incompatiblePharmacist: 'Pharmacien incompatible',
  owners: 'Propriétaires',
  pendingActivationList: "Liste d'activation en attente",
  representFranchisenumber: 'Représente le numéro de franchise de la pharmacie',
  pharmacistDuringWeek: 'Pharmaciens pendant la semaine',
  pharmacistOnTheWeekend: 'Pharmaciens le week-end',
  dontSendReminder: "Ne pas m'envoyer de rappel",
  technicianHourlyRate: 'Taux horaire du technicien',
  editProfile: 'Modifier les détails du profil de {0}',
  user: 'Utilisateur',
  enterNameEmail: 'Entrer nom/courriel',
  takeOwnership: 'Prendre possession',
  activate: 'Activer',
  activateUser: "Activer l'utilisateur",
  areYouSureActivateUser: 'Êtes-vous sûr de vouloir activer cet utilisateur ?',
  areYouSureDeleteEntry: 'Êtes-vous sûr de vouloir supprimer cette entrée ?',
  takeOwnershipProfile: 'Voulez-vous prendre possession de ce profil ?',
  chooseFile: 'Choisir le fichier',
  sizeOfImage: "La taille de l'image en Mo doit être inférieure à 2 Mo.",
  addNotes: 'Ajouter des notes',
  uploadYourPicture: 'Téléchargez votre photo',
  deletedRequests: 'Demandes supprimées',
  contactEmail: 'Courriel de contact',
  deleteEvent: 'Supprimé {0}',
  userType: 'Type d’utilisateur',
  all: 'Tous',
  details: 'Détails',
  sizeOfResume:
    'Votre fichier de CV ne doit pas dépasser 2 Mo. Le fichier de CV doit être au format "doc", "docx" ou "pdf".',
  profileDataSaved: 'Les données du profil ont été enregistrées',
  createNew: 'Créer nouveau',
  description: 'Description',
  descriptions: 'Descriptions',
  published: 'Publié',
  publish: 'Publier',
  addNew: 'Ajouter nouveau',
  inUse: 'En usage',
  active: 'Actif',
  sendCopiesOfSf: 'Envoyer des copies des messages SF à',
  adminConfiguration: 'Configuration admin',
  defaultHourlyRate: 'Taux horaire par défaut de {0}',
  customSettings: 'Paramètres personnalisés de {0}',
  salesforce: 'Salesforce',
  customerServiceRepresentative: 'Représentant du service clientèle',
  more: 'Plus',
  noOptions: 'Pas d’options',
  ownedByAnotherAdmin: 'Possédé par un autre admin',
  userActivatedSuccessfully: 'Utilisateur activé avec succès',
  recordDeletedSuccessfully: 'Enregistrement supprimé avec succès',
  success: 'Succès !',
  pharmacistGeographicAvailability: 'Disponibilité géographique du pharmacien',
  outOfDate: 'Périmé',
  detailsSaved: 'Détails enregistrés avec succès',
  editAdminConfig: 'Modifier la configuration admin',
  sfAdminCreatedSuccessfully: 'Admin SF créé avec succès',
  sfAdminDeletedSuccessfully: 'Admin SF supprimé avec succès',
  sizeValidation:
    'Taille maximale de téléchargement 100 ko et dimensions 250 x 250 pixels.',
  uploadFile: 'Télécharger le fichier',
  cancelledBookings: 'Réservation annulée',
  rejectedCancellations: 'Annulations rejetées',
  pendingBookingCancellations: 'Annulations de réservation en attente',
  requestCode: 'Demander le code',
  cancelationRequested: 'Annulation demandée {0}',
  requestedBy: 'Demandé par',
  cancelation: 'Annulation',
  requestCancellationInfo: 'Demander les infos d’annulation',
  bookingInfo: 'Infos de réservation',
  requestStatus: 'Statut de la demande',
  requestType: 'Type de demande',
  requestReason: 'Raison de la demande',
  workshiftCode: 'Code de quart de travail',
  availabilityCode: 'Code de disponibilité',
  cancelationDate: 'Date d’annulation',
  rejectedDate: 'Date de rejet',
  rejectedBy: 'Rejeté par',
  rejectionNote: 'Note de rejet',
  cancellationCode: 'Code d’annulation',
  cancellationDate: 'Date d’annulation',
  approvedBy: 'Approuvé par',
  cancellationNote: 'Note d’annulation',
  rejectedCancellationInfo: 'Infos sur l’annulation rejetée',
  cancellationInfo: 'Infos sur l’annulation',
  rejectCancellation: 'Rejeter l’annulation',
  approvedSuccess: 'Approuvé avec succès',
  rejectedSuccess: 'Rejeté avec succès',
  activePharmacists: 'Pharmaciens actifs',
  deactivatedPharmacists: 'Pharmaciens désactivés',
  hiddenPharmacists: 'Pharmaciens cachés',
  deletedPharmacists: 'Pharmaciens supprimés',
  enterNotesHere: 'Entrez les notes ici',
  showBookings: 'Afficher les réservations',
  pharmacistBookingList: 'Liste des réservations de pharmaciens',
  pharmacyBookingDetails: 'Détails des réservations de la pharmacie',
  showAvailabilities: 'Afficher les disponibilités',
  pharmacistAvailabilities: 'Disponibilités des pharmaciens',
  findMatchingWorkshifts: 'Trouver des quarts de travail correspondants',
  areYouSureHidePharmacist: 'Êtes-vous sûr de vouloir cacher ce pharmacien ?',
  hidePharmacist: 'Cacher le pharmacien',
  areYouSureDeactivatePharmacist:
    'Êtes-vous sûr de vouloir désactiver ce pharmacien ?',
  deactivatePharmacist: 'Désactiver le pharmacien',
  deactivatedSuccessfully: 'Désactivé avec succès',
  hiddenSuccessfuly: 'Caché avec succès',
  pleaseEnterValidValue: 'Veuillez entrer une valeur valide',
  requestedEvent: 'Demandé {0}',
  fromMustBeLessThanTo:
    "La valeur de '{0}' doit être inférieure à celle de '{1}'",
  toMustBeMoreThan: '0 doit être plus grand que {1}',
  areYouSurePublish: 'Êtes-vous sûr de vouloir {0} l’enregistrement ?',
  unpublish: 'Retirer de la publication',
  recordFormat: 'Enregistrement {0}',
  uploadResume: 'Télécharger le CV',
  uploadPicture: 'Télécharger la photo',
  noResultsToDisplay: 'Aucun résultat à afficher.',
  owner: 'Propriétaire',
  ownedBy: 'Possédé par',
  createMultipleAvailabilities: 'Créer plusieurs disponibilités',
  availabilitiesCreatedSuccessfully: 'Disponibilités créées avec succès',
  matchingWorkshiftsOfAvailability:
    'Quarts de travail correspondant à la disponibilité',
  noAvailabilitiesToShow: 'Aucune disponibilité à afficher.',
  noMatchingWorkshiftsToShow: 'Aucun quart de travail correspondant à afficher',
  dismiss: 'Rejeter',
  pharmacySoftwareWarning:
    'La connaissance du logiciel de pharmacie de ce pharmacien est insuffisante ({1}%). Êtes-vous sûr de vouloir continuer ?',
  addedSuccessfullyFormat: 'Données ajoutées avec succès',
  updatedSuccessfullyFormat: 'Données mises à jour avec succès',
  ensureTimeIsAhead:
    "Veuillez vous assurer que l'heure de fin est au moins {0} heures plus tard.",
  listOfPharmacies: 'Liste des pharmacies',
  notesForPharmacy: 'Notes pour la pharmacie',
  enterAReason: 'Veuillez entrer une raison',
  deactivationDate: 'Date de désactivation',
  unhide: 'Réafficher',
  areYouSureActivatePharmacist:
    'Êtes-vous sûr de vouloir activer ce pharmacien ?',
  activateFormat: 'Activer {0} ?',
  unhideFormat: 'Réafficher {0}',
  areYouSureUnhidePharmacist:
    'Êtes-vous sûr de vouloir réafficher ce pharmacien ?',
  deletion: 'Suppression',
  actionCompletedSuccessfully: 'Action réalisée avec succès',
  appGlobalSettings: 'Paramètres globaux de {0}',
  pharmacistList: 'Liste des pharmaciens',
  listOfPharmacists: 'Liste des pharmaciens',
  listOfWorkshifts: 'Liste des quarts de travail',
  deactivate: 'Désactiver',
  noAvailable: 'Aucun {0} disponible !',
  hide: 'Cacher',
  listOfBookedPharmacists: 'Liste des pharmacies réservées',
  incompatiblePharmacistDescription:
    'Les pharmaciens jugés incompatibles avec cette pharmacie ne pourront pas voir les quarts de travail publiés par cette pharmacie.',
  allPharmacists: 'Tous les pharmaciens',
  add: 'Ajouter',
  selectAPharmacy: 'Sélectionner une pharmacie',
  updateList: 'Mettre à jour la liste',
  incompatibleMatchPharmacist: 'Incompatibilité - Pharmacien',
  updateIncompatibleMatchPharmacist:
    'Mettre à jour l’incompatibilité - Pharmacien',
  emptyIncompatiblePrompt:
    'Aucune donnée disponible à afficher. Veuillez rechercher à l’aide des filtres ci-dessus.',
  saveIncompatibleMatch: 'Enregistrer l’incompatibilité',
  areYouSureSaveIncompatible:
    'Êtes-vous sûr de vouloir enregistrer la liste des pharmaciens incompatibles mise à jour ?',
  youCannotMoveMoreIncompatiblePharmacists:
    'Vous ne pouvez pas déplacer plus d’un pharmacien vers la liste des pharmaciens incompatibles.',
  youMoveUserIncompatiblePharmacist:
    'Vous avez déplacé “{0}” dans la liste des pharmaciens incompatibles. Enregistrez pour confirmer les changements.',
  deleteRecords: 'Supprimer les enregistrements',
  selectAll: 'Sélectionner tout',
  deleteSelected: 'Supprimer la sélection',
  thisRecordDeletePermanently:
    'Cet enregistrement sera définitivement supprimé du système. Êtes-vous sûr de vouloir continuer ?',
  areYouSureActivatePharmacy:
    'Êtes-vous sûr de vouloir activer cette pharmacie ?',
  areYouSureDeactivatePharmacy:
    'Êtes-vous sûr de vouloir désactiver cette pharmacie ?',
  deactivateFormat: 'Désactiver {0}',

  //translate

  workshiftCreatedSuccessfully: 'Quart de travail créé avec succès',
  availabilityCreatedSuccessfully: 'Disponibilité créée avec succès',
  workshiftDeletedSuccessfully: 'Quart de travail supprimé avec succès',
  noIncompatibleMatches:
    "Désolé, cette pharmacie n'a pas de correspondances incompatibles.",
  replacementHourlyRate: 'Taux horaire du remplaçant',
  wsCode: 'Code WS',
  replacementName: 'Nom du remplaçant',
  free: 'Libre',
  deletedDate: 'Date de suppression',
  deactivatedDate: 'Date de désactivation',
  findMatchingAvailabilities: 'Trouver des disponibilités correspondantes',
  pleaseEnterStartAndEndDate:
    'Veuillez entrer la date de début et de fin pour voir le rapport',
  dateTimeSuffix: 'Date | Heure de {0}',
  lastActivity: 'Dernière activité',
  lastLogin: 'Dernière connexion',
  superadmin: 'Superadmin',
  inactive: 'Inactif',
  areYouSureDeactivateFormat: 'Êtes-vous sûr de vouloir désactiver ce {0} ?',
  areYouSureActivateFormat: 'Êtes-vous sûr de vouloir activer ce {0} ?',
  viewPharmacyAdministrators: 'Voir les administrateurs de la pharmacie',
  editProfileOnly: 'Modifier le profil',
  bookings: 'Réservations',
  adminNotes: "Notes de l'admin",
  view: 'Voir {0}',
  historyOfBookings: 'Historique des réservations',
  notesForPharmacist: 'Notes pour le pharmacien',
  contactPreference: 'Préférence de contact',
  confirmDeleteRecord:
    'Êtes-vous sûr de vouloir supprimer cet enregistrement de configuration ?',
  deletedSuccessfully: 'Supprimé avec succès !',
  deleteConfiguration: 'Supprimer la configuration',
  type: 'Type',
  replacement: 'Remplacement',
  createMultipleFullDay: 'Créer plusieurs disponibilités sur toute la journée',
  adminContinueCreation:
    'Souhaitez-vous créer plus de {0} ? Si non, vous serez redirigé vers la liste des pharmaciens',
  posting: 'Publication',
  changeParameters: 'Modifier les paramètres',
  activation: 'Activation',
  setupIncompatibleMatch: 'Configurer un appariement incompatible',
  logsView: 'Vue des journaux',
  broadcastNotification: 'Notification de diffusion',
  addNewFormat: 'Ajouter un(e) nouveau/nouvelle {0}',
  selectPermissions: 'Sélectionner les permissions',
  areYouSureEditEmail: 'Êtes-vous sûr de vouloir modifier cet e-mail ?',
  setPassword: 'Définir le mot de passe',
  confirmWorkshiftCancel:
    'Êtes-vous sûr de vouloir annuler le quart de travail ?',
  bookingCreatedSuccessfully: 'Réservation créée avec succès !',
  bookingEditedSuccessfully: 'Réservation modifiée avec succès !',
  activePharmacies: 'Pharmacies actives',
  deactivatedPharmacies: 'Pharmacies désactivées',
  deletedPharmacies: 'Pharmacies supprimées',
  bannerLogo: 'Logo bannière',
  broadcast: 'Diffusion',
  pharmacistRateCannotBeMoreThanPharmacy:
    'Le taux du pharmacien ne peut pas être supérieur au taux de la pharmacie.',
  confirmDeleteAvailability:
    'Êtes-vous sûr de vouloir supprimer cette disponibilité ?',
  adminCreatedSuccessfully: 'Admin créé avec succès',
  accountDetailsHaveBeenEmailed:
    'Les détails du compte ont été envoyés par courriel au nouvel utilisateur',
  areYouSureCreateAvailabilities:
    'Êtes-vous sûr de vouloir créer ces disponibilités ?',
  pharmaciesList: 'Liste des pharmacies',
  administrators: 'Administrateurs',
  administratorsList: 'Liste des administrateurs',
  activeAdmins: 'Admins actifs',
  inactiveAdmins: 'Admins inactifs',
  userDeletedAccounts: 'Comptes supprimés par l’utilisateur',
  deletedActivationRequests: 'Demandes d’activation supprimées',
  tech: 'Tech',
  changeEmailConfirmation:
    'Cela nécessite de passer à un utilisateur de courriel PharmaPlan au lieu de {0} et de créer un mot de passe. Ne vous inquiétez pas, nous vous guiderons à travers les étapes !',
  continue: 'Continuer',
  weSentAnEmail:
    'Nous avons envoyé un courriel à {0} avec un lien pour créer votre mot de passe.',
  pleaseEnterCurrentPassword:
    'Veuillez entrer votre mot de passe actuel et votre nouveau courriel pour continuer.',
  currentPassword: 'Mot de passe actuel',
  newEmail: 'Nouveau courriel',
  setNewEmail: 'Définir le nouveau courriel',
  sessionReset: 'Réinitialisation de la session',
  logoutConfirmation:
    'Succès ! Votre courriel a été mis à jour avec succès. Veuillez noter que vous serez automatiquement déconnecté de toutes les sessions actives et devrez vous reconnecter ultérieurement',
  otpSentNew: 'Un OTP a été envoyé à votre nouveau courriel',
  timeAboveValidation: 'Veuillez vous assurer que le {0} est réglé après {1}',
  timeBelowValidation: 'Veuillez vous assurer que le {0} est réglé avant {1}',
  upcomingWorkshifts: 'Quarts de travail à venir',
  pastWorkshifts: 'Quarts de travail passés',
  pharmacyFeedback: 'Commentaires sur la pharmacie',
  allRatings: 'Toutes les évaluations',
  createdOn: 'Créé le',
  averageRating: 'Note moyenne',
  editNotAllowed: 'Modification non autorisée',
  thisFeatureIsUnderDev:
    'Cette fonction est actuellement en développement et sera bientôt disponible.',
  level: 'Niveau',
  detailsSearch: 'Recherche détaillée',
  capacityFrom: 'Capacité à partir de',
  useLabSchedulingExt:
    'Utiliser les extensions de planification de laboratoire ?',
  connectedUsers: 'Utilisateurs connectés',
  permanentDelete: 'Suppression permanente',
  by: 'Par',
  groupName: 'Nom du groupe',
  recepients: 'Destinataires',
  notificationType: 'Type de notification',
  sent: 'Envoyé',
  group: 'Groupe',
  broadcastGroups: 'Groupes de diffusion',
  createNewGroup: 'Créer un nouveau groupe',
  saveGroup: 'Enregistrer le groupe',
  selectedUsers: 'Utilisateurs sélectionnés',
  groupCreatedSuccessfully: 'Groupe créé avec succès',
  areYouSureResetSelect:
    'Êtes-vous sûr de vouloir réinitialiser votre sélection ? Veuillez noter qu’en cliquant sur oui, tous les éléments sélectionnés seront désélectionnés',
  resetSelection: 'Réinitialiser la sélection',
  editGroup: 'Modifier le groupe',
  clickingYesWillOverride:
    "Cliquer sur 'Oui' remplacera les sélections précédentes.",
  updateGroupName: 'Mettre à jour le nom du groupe',
  backToBroadcastGroups: 'Retour aux groupes de diffusion',
  message: 'Message',
  pushNotification: 'Notification push',
  subject: 'Sujet',
  createNewNotification: 'Créer une nouvelle notification',
  backToBroadcastNotifications: 'Retour aux notifications de diffusion',
  createNewBroadcastNotification:
    'Créer une nouvelle notification de diffusion',
  selectANotificationType: 'Sélectionnez un type de notification',
  enterEmailData: 'Entrez les données de courriel',
  selectGroup: 'Sélectionnez un groupe',
  emailSubject: 'Sujet du courriel',
  emailBody: 'Corps du courriel',
  gmail: 'Gmail',
  facebook: 'Facebook',
  apple: 'Apple',
  writeEmailContent: 'Écrivez le contenu de votre courriel ici',
  sendNotification: 'Envoyer la notification',
  resendNotification: 'Renvoyer la notification',
  editBroadcastNotification: 'Modifier la notification de diffusion',
  areYouSureWantSendNotification:
    'Êtes-vous sûr de vouloir envoyer une notification aux groupes sélectionnés ?',
  sentSuccessfully: 'Envoyé avec succès',
  pleaseSelectNotificationType:
    'Veuillez sélectionner un type de notification pour créer une diffusion.',
  enterMessageData: 'Entrez les données du message',
  yourSessionHasTimedOut:
    'Votre session a expiré. Veuillez vous reconnecter pour accéder à l’application.',
  sessionTimedout: 'Session expirée',
  sessionCode: 'Code de session',
  createdDate: 'Date de création',
  createdTime: 'Heure de création',
  userName: 'Nom d’utilisateur',
  module: 'Module',
  workshiftLogs: 'Journaux des quarts de travail',
  availabilityLogs: 'Journaux de disponibilité',
  bookingLogs: 'Journaux des réservations',
  performer: 'Interprète',
  actionDateTime: 'Date | Heure de l’action',
  cancelBookingRequest: 'Annuler la demande de réservation',
  updateRateAndAllowance: 'Mettre à jour le taux et les allocations',
  selectOnePharmacistToMove:
    'Sélectionnez un pharmacien à la fois pour le déplacer vers la liste incompatible.',
  textMessageBody: 'Corps du message texte',
  writeMessageContent: 'Écrivez le contenu de votre message ici',
  allPharmacies: 'Toutes les pharmacies',
  searchAndSelectPharmacies:
    "Recherchez et sélectionnez les pharmacies à ajouter à la liste 'Mes pharmacies'.",
  atleastTwoPharmacies:
    'Au moins deux pharmacies doivent être ajoutées à cette liste.',
  lastLoginLongTime: 'La dernière connexion remonte à longtemps.',
  watchlistReport: 'Rapport de liste de surveillance',
  addToWatchlist: 'Ajouter à la liste de surveillance',
  removeFromWatchlist: 'Retirer de la liste de surveillance',
  areYouSureRemoveWatchlist:
    'Êtes-vous sûr de vouloir retirer ce quart de travail de la liste de surveillance ?',
  pharmacistAvgRating: 'Note moyenne du pharmacien',
  userNotFound: 'Utilisateur non trouvé',
  selectBanner: 'Sélectionner la bannière',
  selectSoftware: 'Sélectionner le logiciel',
  selectAction: "Sélectionner l'action",
  thisUserIsNotActive: "Désolé, cet utilisateur n'est pas actif.",
  users: 'Utilisateurs',
  allUsers: 'Tous les utilisateurs',
  editEmail: "Modifier l'email",
  confirmEditEmail: "Êtes-vous sûr de vouloir modifier l'email ?",
  selfService: 'Libre-service',
  requestPendingPeriod: 'Période de demande en attente (heures)',
  noCancellationPeriod: 'Période sans annulation (jours)',
  cancellationFees: "Frais d'annulation",
  freeCancellationPeriod: "Période d'annulation gratuite",
  pharmacistReminderPost: 'Rappel pharmacien 1 (post-approbation)',
  pharmacistReminderPre: 'Rappel pharmacien 2 (pré-expiration)',
  hrs: 'Hrs',
  days: 'Jours',
  configurations: 'Configurations',
  appointExistingPharmacy: 'Nommer une pharmacie existante',
  createNewPharmacyAdmin: 'Créer un nouvel administrateur de pharmacie',
  editPharmacyAdmin: "Modifier l'administrateur de la pharmacie",
  backToPharmacyAdmin: 'Retour aux administrateurs de pharmacie',
  pharmacyAdministratorCreatedSuccessfully:
    'Administrateur de pharmacie créé avec succès',
  pharmacyAdministratorUpdatedSuccessfully:
    'Administrateur de pharmacie mis à jour avec succès',
  deletePharmacyAdmin: "Supprimer l'administrateur de la pharmacie",
  areYouSureDeletePharmacyAdmin:
    'Êtes-vous sûr de vouloir supprimer cet administrateur de pharmacie ?',
  pharmacyAppointedSuccessfully:
    'Administrateur de pharmacie nommé avec succès',
  appoint: 'Nommer',
  appointPharmacyAdmin: "Nommer l'administrateur de la pharmacie",
  appointedPharmacies: 'Pharmacies nommées',
  backToAppointPharmacy: 'Retour à la nomination de pharmacie',
  pharmacyAdministrator: 'Administrateur de la pharmacie',
  enterPharmacy: 'Entrer dans la pharmacie',
  pharmacyAdminDescription:
    'Vous pouvez trouver toutes vos pharmacies ici. Veuillez sélectionner une pharmacie et continuer. Vous pouvez changer votre sélection de pharmacie dans les ‘Paramètres’ à tout moment.',
  totalContractValue: 'Valeur totale du contrat',
  lastLoggedIn: 'Dernière connexion il y a {0}',
  contractBreakdown: 'Répartition du contrat',
  watchlistedPharmacyWorkshifts:
    'Quarts de travail en pharmacie sur liste de surveillance',
  watchlist: 'Liste de surveillance',
  deleteFromGroup: 'Supprimer du groupe',
  createdBy: 'Créé par',
  switchPharmacy: 'Changer de pharmacie',
  addedToWatchlist: 'Ajouté à la liste de surveillance',
  removedFromWatchlist: 'Retiré de la liste de surveillance',
  communicationsEmail: 'Courriel de communications',

  pleaseSelectDay: 'Veuillez sélectionner un jour',
  confirmDeleteWorkshifts:
    'Êtes-vous sûr de vouloir supprimer ces quarts de travail?',
  techDuringWeek: 'Techniciens pendant la semaine',
  techDuringWeekend: 'Techniciens le week-end',
  vacations: 'Vacances',
  vacation: 'Vacance',
  newVacation: 'Nouvelle Vacance',
  createVacation: 'Créer une Vacance',
  deleteVacation: 'Supprimer la Vacance',
  areYouSureDeleteVacation: 'Êtes-vous sûr de vouloir supprimer cette vacance?',
  workshiftRequestConflict:
    "Une demande de quart de travail existe pour ces jours. Créer une vacance l'annulera. Voulez-vous continuer?",
  workshiftBookedConflict:
    'Un quart de travail est réservé pour ces jours. Désolé, cette action ne peut pas être effectuée.',
  vacationCreatedSuccessfully: 'Vacance créée avec succès',
  updateVacation: 'Mettre à jour les vacances',
  vacationAvailabilityConflict:
    'Une disponibilité existe déjà pour ce jour. Êtes-vous sûr de vouloir la remplacer par des vacances ?',
  vacationRequestedConflict:
    'Un quart de travail a déjà été demandé pour ce jour. Si vous continuez, votre demande de réservation sera annulée. Êtes-vous sûr de vouloir continuer ?',
  vacationBookedConflict:
    'Désolé, vous ne pouvez pas créer de vacances pour cette plage horaire car vous avez déjà une réservation.',
  multipleVacationAvailabilitiesConflict:
    'Il existe déjà des disponibilités pour cette plage horaire. Êtes-vous sûr de vouloir les remplacer par des vacances ?',
  multipleVacationRequestedConflict:
    'Des quarts de travail ont déjà été demandés pour certains jours. Si vous continuez, vos demandes de réservation seront annulées. Êtes-vous sûr de vouloir continuer ?',
  multipleVacationBookedConflict:
    "Il est impossible de créer des vacances pour cette plage horaire en raison d'une réservation existante.",
  adminVacationAvailabilitiesConflict:
    'Il existe déjà des disponibilités pour ce pharmacien dans cette plage horaire. Êtes-vous sûr de vouloir les remplacer par des vacances ?',
  adminVacationBookedConflict:
    "Désolé, il est impossible de créer des vacances pour cette plage horaire en raison d'une réservation existante.",
  adminVacationRequestedConflict:
    'Des quarts de travail ont déjà été demandés pour certains jours. Si vous continuez, les demandes de réservation du pharmacien seront annulées. Êtes-vous sûr de vouloir continuer ?',
  adminVacationRequestedAndAvailabilityConflict:
    'Il existe déjà une disponibilité et une demande de réservation pour cette plage horaire. Si vous continuez, la demande de réservation ou la disponibilité actuelle de ce pharmacien sera annulée et remplacée par des vacances. Êtes-vous sûr de vouloir continuer ?',
  createAvailability: 'Créer une disponibilité',
  availabilitiesConflict:
    'Des vacances sont déjà prévues pour cette plage horaire. Créer une disponibilité maintenant remplacera les vacances existantes. Voulez-vous continuer ?',
  deleteAvailabilityOrVacation: 'Supprimer vacances/disponibilité',
  deleteAvailabilityOrVacationConfirm:
    'Êtes-vous sûr de vouloir supprimer les vacances/disponibilités pour les dates sélectionnées ?',
  multipleVacationsOrAvailabilities: 'Multiples Disponibilités/Vacances',
  addVacation: 'Ajouter des vacances',
  multipleAddConfirmation:
    'Voulez-vous ajouter plusieurs disponibilités ou vacances ?',
  availabilitiesOrVacations: 'Disponibilités/Vacances',
  availabilityAlreadyRequested:
    'La disponibilité que vous essayez de supprimer est déjà demandée pour un autre quart de travail. Êtes-vous sûr de vouloir continuer ?',
  weekSpaceDays: 'Jours de la semaine',
  newFormat: 'nouveau {0}',
  registrationBasedOnActivation:
    "Inscriptions basées sur le statut d'activation",
  newRegistrations: 'Nouvelles Inscriptions',
  registrationStatistics: 'Statistiques des Inscriptions',
  activeUserStatistics: 'Statistiques des Utilisateurs Actifs',
  activeUsersBasedOnLogin: "Utilisateurs actifs selon l'activité de connexion",
  activeUsersBasedOnActions: 'Utilisateurs actifs selon les actions effectuées',
  workshiftsStatistics: 'Statistiques des Quarts de Travail',
  workshiftTypes: 'Quarts de travail (Publiés, Demandés, et Réservés)',
  cancellationRequests: "Demandes d'annulation",
  numberOfWorkshift: '# de {0} QT',
  cancellationByUser: 'Annulation demandée par {0}',
  financialStatistics: 'Statistiques Financières',
  financialInsights: 'Aperçus Financiers',
  pharmacyBilling: 'Facturation de la Pharmacie',
  pharmacistPayout: 'Paiement au Pharmacien',
  pharmaplanGrossProfit: 'Bénéfice Brut de PharmaPlan',
  averageFinancialInsights: 'Aperçus Financiers Moyens',
  averageFinancialSummary: 'Résumé Financier Moyen',
  averagePharmacyRate: 'Taux Moyen de la Pharmacie',
  averagePharmacistRate: 'Taux Moyen du Pharmacien',
  averageHourlyProfit: 'Profit Horaire Moyen',
  totalBookedHours: 'Total des Heures Réservées',
  approvedUsers: '{0} Approuvés',
  pharmacistsPendingActivation: "Pharmaciens en attente d'activation",
  pharmaciesPendingActivation: "Pharmacies en attente d'activation",
  otherActivities: 'Autres Activités',
  pharmaplanStatistics: 'Statistiques de PharmaPlan',
  dailyReport: 'Rapport Quotidien',
  pendingCancellationRequests: "Demandes d'annulation en attente",
  pharmacyRegistrations: 'Inscriptions des Pharmacies',
  pharmacistRegistrations: 'Inscriptions des Pharmaciens',
  workshiftPostings: 'Affichages de Quarts de Travail',
  quarter: 'Trimestre',
  vacationRequestedAndAvailabilityConflict:
    'Il existe déjà une disponibilité et une demande de réservation pour cette période. Si vous continuez, votre demande de réservation ou de disponibilité actuelle sera annulée et remplacée par des vacances. Si vous continuez, celles-ci seront supprimées et remplacées par des vacances.',
  away: 'absent',
  newUserRegistrations: 'Nouveaux Utilisateurs - inscriptions',
  confirmDeleteCountWorkshift:
    'Êtes-vous sûr de vouloir supprimer les {0} quarts de travail sélectionnés?',
  updateProfile: 'mettre à jour le profil',
  actionCannotBePerformed: 'Action ne peut pas être effectuée',
  expressReport: 'Rapport express',
  chat: 'Chat',
  engaged: 'Occupé',
  assignedToMe: 'Assigné à moi',
  idle: 'Inactif',
  closeChat: 'Fermer le chat',
  newChat: 'Nouveau chat',
  enableChat: 'Activer le chat',
  clickOnUserToDisplayChat:
    'Cliquez sur un utilisateur pour afficher les détails du chat',
  noMessages: 'Aucun message',
  enterMessage: 'Entrez un message',
  chatWithUs: 'Discutez avec nous',
  you: 'Toi',
  takeAVideo: 'Prendre une vidéo',
  photoLibrary: 'Bibliothèque de photos',
  files: 'Fichiers',
  facingIssueWithApp:
    'J’ai un problème avec l’application. Pouvez-vous m’aider ?',
  knowMoreAboutFeatures:
    'Bonjour, j’aimerais en savoir plus sur les fonctionnalités de votre produit.',
  updatePreviousIssue:
    'Bonjour, j’aimerais avoir une mise à jour concernant mon problème précédent.',
  endChat: 'Terminer la conversation',
  areYouSureEndConversation:
    'Êtes-vous sûr de vouloir mettre fin à cette conversation ?',
  supportAgentName: 'Nom de l’agent de soutien',
  viewEngagedChat: 'Voir les conversations engagées',
  shiftCode: 'Code de quart',
  icon: '».',
  pdfReport: 'Rapport PDF',
  dates: 'Dates',
  selectMultipleDates: 'Sélectionner plusieurs dates',
  vacationReport: 'Rapport de vacances',
  weekend: 'Fin de semaine',
  myReplacementPharmacistWalkthrough:
    'La touche « Pharmacies Servies » affiche les pharmacies desservies avec une option d’aperçu historique. Vous pouvez définir des favoris et ajouter des notes pour chacune.',
  mapViewMobileWalkthrough:
    'La « Vue cartographique » vous permet de visualiser l’emplacement géographique des pharmacies affichants des quarts disponibles.  Vous pouvez revenir à la « Vue Calendrier » en appuyant sur l’icône « Vue Calendrier »',
  notAvailableShort: 'N/A',
  saveAndCreate: 'Enregistrer et créer un autre',

  ratingAPharmacistFormat:
    "L'évaluation d'un pharmacien est ouverte uniquement pendant {0} jours après le quart de travail.",
  pleaseEnterStartDate:
    'Veuillez entrer une date de début et une date de fin pour filtrer et consulter votre rapport de revenus.',
  addAvailabilityVacation: 'Ajouter disponibilité/vacances',
  resendEmail: "Renvoyer l'email",
  emailResentSuccess: 'Email renvoyé avec succès',
  emailResentSuccessMsg:
    'Les notifications par email ont été envoyées avec succès aux utilisateurs sélectionnés.',
  sendEmailToPharmacist: 'Envoyer une notification par email au pharmacien',
  sendEmailToPharmacy: 'Envoyer une notification par email à la pharmacie',

  noPharmacistToRate: 'Aucun pharmacien à évaluer',
  customerIsAlreadyEngaged: 'Le client est déjà engagé avec un administrateur',
  engagedWithFormat: 'Engagé avec {0}',
  releaseChat: 'Libérer le chat',
  areYouSureMakeChannelAvailable:
    'Êtes-vous sûr de vouloir rendre ce canal disponible?',
  ratePharmacistAfterBooking:
    'Veuillez évaluer le pharmacien le lendemain de la fin de la réservation.',
  chatGuide:
    "Vous pouvez facilement joindre l'équipe de soutien PharmaPlan pour toute question ou préoccupation en démarrant un chat directement depuis cette section.",
  messageGuide:
    'Entrez votre message ici. Utilisez Maj + Entrée pour une nouvelle ligne.',

  selectAtleastOneEmailOptions:
    '* Au moins une des options de courriel doit être sélectionnée.',
  noWorkshiftsFound: 'Aucun quart de travail trouvé!',
  pleaseSelectRating: 'Veuillez évaluer toutes les catégories ci-dessus!',
};
