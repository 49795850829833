import React, { FC } from 'react';

import { Avatar, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import RefreshIcon from '@mui/icons-material/Refresh';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';

import { profileImage } from '@pharmaplan/common';

import { useAppSelector } from '../../../../hooks/useAppSelector';
import useUser from '../../../../hooks/useUser';

import styles from './styles';
import { MetaData } from '../../../../hooks/Admin/Chat/useMetadata';
import { IChannelData, IChannelURLData } from '../../../../helpers/Constants';

interface IGroupChannelListTitle {
  url?: string;
  setChannelUrl?: React.Dispatch<React.SetStateAction<IChannelData>>;
}

const GroupChannelListTitle: FC<IGroupChannelListTitle> = ({ url, setChannelUrl }) => {
  const { name = '' } = useUser();
  const store = useSendbirdStateContext();

  const avatar = useAppSelector(profileImage);

  const sdk = store?.stores?.sdkStore?.sdk;

  // const onMetaData = (res: MetaData) => {
  //   setChannelUrl((prev: IChannelData) =>
  //     ({
  //       ...prev,
  //       [url]: res as IChannelURLData,
  //     }));
  // };

  // const handleMeta = async () => {
  //   const channelInstance = await sdk?.groupChannel?.getChannelWithoutCache(
  //     url,
  //   );
  //   channelInstance.getAllMetaData().then(onMetaData);
  // };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.avatarContainer}>
        <Avatar src={avatar} sx={styles.avatar} imgProps={{ sx: styles.img }} />
        <Typography sx={styles.name}>
          {name}
        </Typography>
      </Box>
      {/* TODO: remove refresh if not required */}
      {/* <IconButton onClick={handleMeta}>
        <RefreshIcon sx={styles.refreshIcon} />
      </IconButton> */}
    </Box>
  );
};

export default GroupChannelListTitle;
