import { useEffect, useState } from 'react';
import { FormikValues } from 'formik';

import {
  adminActions,
  getPublishedSoftwareList,
  resetStatus,
  successSelector,
} from '@pharmaplan/common';

import { IDynamicTableObject } from '../../../components/DynamicTable/types';
import { showSuccess } from '../../../components/Admin/Profile/Pharmacist/PharmacistViewProfile/helper';

import { useAppDispatch } from '../../useAppDispatch';
import useFormikUtils from '../../useFormik/useFormikUtils';
import { useComponentDidUpdate } from '../../useComponentDidUpdate';
import useFormik from '../../useFormik/useFormik';
import { useAppSelector } from '../../useAppSelector';
import strings from '../../../localization';
import { PharmacyListTabTypes } from '../../../helpers/Constants';

import usePharmacyListTabs from './usePharmacyListTabs';

const initialValues = {
  banners: '',
  userText: '',
  contactName: '',
  city: '',
  softwareId: '',
};

const usePharmacyList = () => {
  const { setPage, page, urlSelectedTab } = useFormikUtils(
    Object.keys(PharmacyListTabTypes),
    PharmacyListTabTypes.activePharmacies,
  );

  const [selectedTab, setSelectedTab] = useState<PharmacyListTabTypes>(urlSelectedTab as PharmacyListTabTypes);

  const dispatch = useAppDispatch();

  const { headers, rows, api, data, loadSuccess } = usePharmacyListTabs(selectedTab);
  const { totalCount, data: rowData } = data ?? {};

  const deactivatedSuccess = useAppSelector((state) =>
    successSelector([adminActions.disapprove], state));

  const activatedSuccess = useAppSelector((state) =>
    successSelector([adminActions.approve], state));

  const handleSubmit = (values: FormikValues, inPage = 1) => {
    const { softwareId, banners, userText, contactName, city } = values ?? {};
    setPage(inPage);

    dispatch(
      api({
        page: inPage,
        softwareId,
        banners,
        userText: userText?.trim(),
        contactName: contactName?.trim(),
        city: city?.trim(),
      }),
    );
  };

  const { formik, clearURL } = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    page,
    selectedTab,
  });

  const { values } = formik ?? {};

  const handlePagination = (_: unknown, selectedPage: number) => {
    const gotoPage = selectedPage + 1;
    setPage(gotoPage);
    dispatch(api({ page: gotoPage, ...values }));
  };

  const handleReset = () => {
    clearURL();
    formik.resetForm();
    setPage(1);
    dispatch(api({ page: 1 }));
  };

  const table: IDynamicTableObject = {
    headerBar: [],
    headers,
    rows,
  };

  useEffect(() => {
    dispatch(getPublishedSoftwareList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useComponentDidUpdate(() => {
    handleReset();
  }, [selectedTab]);

  useEffect(() => {
    if (activatedSuccess) {
      dispatch(resetStatus([adminActions.approve]));
      dispatch(api({ page: 1 }));
      showSuccess(dispatch, strings.userActivatedSuccessfully);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activatedSuccess]);

  useEffect(() => {
    if (deactivatedSuccess) {
      dispatch(resetStatus([adminActions.disapprove]));
      dispatch(api({ page: 1 }));
      showSuccess(dispatch, strings.deactivatedSuccessfully);
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deactivatedSuccess]);

  return {
    table,
    formik,
    handlePagination,
    page,
    setSelectedTab,
    selectedTab,
    handleReset,
    totalCount,
    rowData,
    loadSuccess,
  };
};

export default usePharmacyList;
