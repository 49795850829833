import React, { Fragment, useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  Box,
  TablePagination,
  TableRow,
  Typography,
  Button,
  LabelDisplayedRowsArgs,
  Skeleton,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BackIcon from '@mui/icons-material/ArrowBack';

import { ITEMS_PER_PAGE } from '@pharmaplan/common';
import strings from '../../localization';
import genericClasses from '../../theme/GenericClasses';
import { NavType } from '../../helpers/Constants';
import FilterGenerator from '../common/FilterGenerator';

import {
  IDynamicTable,
  IDynamicTableRow,
  IDynamicTableHeaderBar,
} from './types';
import DynamicCell from './DynamicCell.tsx/DynamicCell';
import classes from './style';

const defaultProps = {
  hidePagination: false,
  showBackButton: false,
  showHeader: true,
  noContainerStyling: false,
};

const DynamicTable = ({
  table,
  hidePagination,
  showFilter,
  loadSuccess,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handlePagination = () => {},
  page = 1,
  formik,
  totalCount,
  filter,
  type,
  showBackButton,
  backFunction,
  customHeader,
  showHeader,
  noContainerStyling,
  customFooter,
  stickyHeader,
  customContainerStyle,
  emptyContainerComponent,
  customTableHeadStyle,
  CustomEndAdornment,
}: IDynamicTable) => {
  const styles = classes('common.gray');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<Array<string>>([]);

  const navConfig = {
    [NavType.history]: strings.history,
    [NavType.notes]: strings.notes,
  };

  return (
    <TableContainer
      sx={
        noContainerStyling ? styles.basicContainerStyle : styles.tableContainer
      }
      component={Paper}
    >
      {type && (
        <Box sx={styles.navContainer}>
          <Box
            component={Button}
            onClick={() =>
              navigate(-1)}
          >
            <Typography sx={styles.replacementText}>
              {strings.myReplacment}
              s
            </Typography>
          </Box>
          <Typography sx={styles.divider}>{'>'}</Typography>
          <Typography sx={styles.labelText} color="primary">
            {navConfig[type]}
          </Typography>
        </Box>
      )}
      {showHeader && (
        <Box sx={styles.tableHeader}>
          <Box component="span">
            {showBackButton ? (
              <Box
                component={IconButton}
                onClick={backFunction}
                sx={styles.backButtonContainer}
              >
                <BackIcon sx={styles.backIcon} />
                <Typography sx={styles.backButtonText}>
                  {`${strings.backTo} ${table.title}`}
                </Typography>
              </Box>
            ) : (
              <Typography variant="h5" sx={styles.tableHeaderText}>
                {table.title}
              </Typography>
            )}
          </Box>

          <Box component="span" sx={genericClasses.actionsFlex}>
            {table.headerBar.map(
              (item: IDynamicTableHeaderBar) =>
                !item.display && (
                  <Box key={item.key} sx={styles.headerContainer}>
                    {item.Component ? (
                      item.Component
                    ) : (
                      <IconButton onClick={item?.onClick}>
                        <Box
                          component="img"
                          src={item?.icon}
                          alt="header bar"
                        />
                      </IconButton>
                    )}
                  </Box>
                ),
            )}
          </Box>
        </Box>
      )}

      {customHeader}

      {filter && showFilter && (
        <Box>
          <FilterGenerator filter={filter} formik={formik} />
        </Box>
      )}
      {emptyContainerComponent ?? (
        <Box sx={customContainerStyle}>
          {!hidePagination && (
            <TableRow sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <TablePagination
                showFirstButton
                showLastButton
                rowsPerPageOptions={[]}
                count={totalCount ?? 1}
                rowsPerPage={ITEMS_PER_PAGE}
                labelDisplayedRows={(info: LabelDisplayedRowsArgs) =>
                  `${info.from}-${info.to} of ${info.count} ${strings.records}`}
                page={page - 1}
                SelectProps={{
                  inputProps: {
                    'aria-label': strings.rowsPerPage,
                  },
                }}
                onPageChange={handlePagination}
              />
            </TableRow>
          )}
          <Table
            stickyHeader={stickyHeader}
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                {table.headers.map((item) =>
                  (
                    <TableCell
                      key={item.key}
                      align={item.isCenterAligned ? 'center' : 'left'}
                      sx={customTableHeadStyle ?? genericClasses.headerStyles}
                      style={{ verticalAlign: 'top' }}
                    >
                      {item.label}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // Map Rows
                loadSuccess ? (
                  table.rows?.map((row) =>
                    (
                      <TableRow key={row.key}>
                        {
                        // Map Cells
                        row?.data?.map((cell: IDynamicTableRow) =>
                          (
                            <Fragment key={cell.key}>
                              <DynamicCell
                                item={cell}
                                loading={loading}
                                setLoading={setLoading}
                                id={row.key}
                              />
                            </Fragment>
                          ))
                      }
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    {table?.headers.map(({ key }) =>
                      (
                        <TableCell key={key}>
                          <Skeleton variant="text" />
                        </TableCell>
                      ))}
                  </TableRow>
                )
              }
            </TableBody>
            <TableFooter>
              {CustomEndAdornment}
              {!hidePagination && (
                <TableRow>
                  <TablePagination
                    showFirstButton
                    showLastButton
                    rowsPerPageOptions={[]}
                    count={totalCount ?? 1}
                    rowsPerPage={ITEMS_PER_PAGE}
                    labelDisplayedRows={(info: LabelDisplayedRowsArgs) =>
                      `${info.from}-${info.to} of ${info.count} ${strings.records}`}
                    page={page - 1}
                    SelectProps={{
                      inputProps: {
                        'aria-label': strings.rowsPerPage,
                      },
                    }}
                    onPageChange={handlePagination}
                  />
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </Box>
      )}
      {customFooter}
    </TableContainer>
  );
};

DynamicTable.defaultProps = defaultProps;

export default DynamicTable;
