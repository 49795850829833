import React, { FC } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import {
  adminReports,
  searchedPharmacists,
  TypeOfUser,
} from '@pharmaplan/common';
import { FormikProps } from 'formik';
import CustomDateRange from '../../../common/CustomDateRange';
import CustomDropdown from '../../../common/CustomDropdown';
import FilterSubmitButtons from '../../../common/FilterSubmitButtons';
import CustomAutocomplete from '../../../common/CustomAutocomplete';
import { toOptions } from '../../ExpressBooking/ExpressBooking';
import DownloadPDF from '../../../common/DownloadPDF';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import useAdminReportsHandler from '../../../../hooks/AdminReports/useAdminReportsHandler';
import strings from '../../../../localization';

import useAdminReportsPDF from '../../../../hooks/AdminReports/useAdminReportsPDF';
import useAutoCompleteScroll, {
  AutoCompleteScrollTypes,
} from '../../../../hooks/useAutoCompleteScroll';
import { getDisabledSubmitFilter } from '../../../../selectors/calendarSelector';
import SelectAllHeader from '../../../DynamicTable/SelectAllHeader';

import styles from './style';

interface IAdminReportsHeader {
  formik: FormikProps<any>;
  handleReset: () => void;
  selectAll: boolean;
  handleSelectAll: (toBeSet: boolean) => void;
  handleDelete: () => void;
  selectableData: Array<string>;
}

const AdminReportsHeader: FC<IAdminReportsHeader> = ({
  formik,
  handleReset,
  selectAll,
  handleDelete,
  handleSelectAll,
  selectableData,
}) => {
  const { menuItems, isAvailabilities, title, isPosted, sorByOptions } = useAdminReportsHandler();
  const { exportPDF } = useAdminReportsPDF(formik);
  const { handleSubmit, values } = formik ?? {};

  const { onEndReached, pharmacistListData } = useAutoCompleteScroll(
    AutoCompleteScrollTypes.pharmacist,
  );
  const { pharmacists: formikPharmacists } = formik.values ?? {};

  const { startDate, endDate } = values ?? {};

  const disableSubmit = useAppSelector(getDisabledSubmitFilter);
  const pharmacists = useAppSelector(searchedPharmacists);
  const reports = useAppSelector(adminReports);
  const { data } = reports ?? {};

  const noData = data.length === 0;
  const noSelectableData = selectableData?.length === 0;

  const showSelectedHeader = !noData && !noSelectableData && isPosted;

  const pharmacistOptions = toOptions(pharmacists);

  const pharmacistOption = formikPharmacists
    ? pharmacistOptions
    : pharmacistListData;

  const placeholder = strings.formatString(
    strings.enterCred,
    `${strings.name}/${strings.email}`,
  );

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={styles.header}>
        <Typography sx={styles.titleFont}>{title}</Typography>
        <DownloadPDF disabled={noData} onClick={exportPDF} />
      </Box>

      <Box sx={styles.filterContainer}>
        <Grid container xs={9} alignItems="center" wrap="nowrap">
          <CustomDateRange
            startName="startDate"
            endName="endDate"
            formik={formik}
            preventPast={false}
          />

          {isAvailabilities && (
            <>
              <Grid item xs={5}>
                <CustomAutocomplete
                  customAutoCompleteStyle={styles.autocomplete}
                  label={strings.pharmacist}
                  idName="pharmacistId"
                  infiniteScroll={!formikPharmacists}
                  textName="pharmacists"
                  autoCompleteName="pharmacistSelected"
                  onEndReached={onEndReached(TypeOfUser.pharmacist)}
                  placeholder={placeholder as string}
                  formik={formik}
                  options={pharmacistOption}
                />
              </Grid>
              <Grid item xs={5} sx={styles.container}>
                <CustomDropdown
                  label={strings.status}
                  menuItems={menuItems}
                  formik={formik}
                  name="availabilityStatus"
                />
              </Grid>
            </>
          )}

          {!isAvailabilities && (
            <Grid item xs={5} sx={styles.container}>
              <CustomDropdown
                label={strings.replacementType}
                menuItems={menuItems}
                formik={formik}
                name="replacementType"
              />
            </Grid>
          )}
          <FilterSubmitButtons
            disableSubmit={startDate && endDate && disableSubmit}
            handleReset={handleReset}
          />
        </Grid>
        <Box sx={styles.sortContainer}>
          <CustomDropdown
            customClass={styles.filterDropdown}
            formik={formik}
            id="sortBy"
            name="sortBy"
            menuItems={sorByOptions}
            placeholder="sort by"
            showPlaceholder
          />
        </Box>
        {showSelectedHeader && (
          <SelectAllHeader
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            showDelete
            handleDelete={handleDelete}
          />
        )}
      </Box>
    </Box>
  );
};

export default AdminReportsHeader;
