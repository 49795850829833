import { createReducer } from '@reduxjs/toolkit';

import {
  IAdminWorkshiftDetails,
  IAdminActiveUsersData,
  IAdminCalendar,
  IAvailableWorkshiftData,
  IAdminRequestData,
  IAdminRequestedPharmacistsData,
  IAdminRequestedDetailsData,
  IAdminIgnoreData,
  IAdminApproveDetailsData,
  IBookedPharmacistData,
  IMatchingAvailabilities,
  IAdminBookedPharmacistDetails,
  IRequestCancellationData,
  IMatchingAvailabilitiesData,
  IPharmacyDetailModel,
  IAdminSearchResponse,
  IAdminPendingActivationsResponse,
  IPharmacistDetailsModel,
  IAdminPharmacistCapacityList,
  IAdminPharmacistCapacityItem,
  IAdminContactPreferences,
  IAdminConfiguration,
  ISalesforceAdmin,
  IAdminPharmacyChainResponse,
  IPharmacistRegionList,
  IPendingCancellationsResponse,
  IPharmacistResume,
  IPendingCancellationCodeDetails,
  ICancelledBookingDetails,
  IAdminPharmacistListApiResponse,
  IAdminNotesResponse,
  IAdminPharmacistBookingResponse,
  IAdminPharmacistBooking,
  IAdminShowAvailabilitiesResponse,
  IMatchingWorkshiftsResponse,
  IAdminPharmacistPictures,
  IPendingActivationsCount,
  IOwnersList,
  IAdminListOfPharmaciesResponse,
  IAdminPharmacistHistoryResponse,
  IAdminPharmacistNotesData,
  IAdminPharmacistListResponse,
  IActivePharmacyList,
  IPublishedSoftwareList,
  IAdminListOfPharmacistsResponse,
  IAdminIncompatibleMatchResponse,
  IAdminPharmacyWorkshiftsList,
  IAdminIncompatibleMatchesSearch,
  IAdminReportsResponse,
  AdminLegendVariant,
  IAdminReportsFilter,
  IAdminReportsPDF,
  IAdminListingResponse,
  IPermissionsResponse,
  IAdminDetailsResponse,
  IAdminPharmacistDetailsSearchResponse,
  IAdminPharmacistDetailsSearchFilters,
  IAdminPharmacyNotesResponse,
  IAdminPharmaciesInfo,
  IRatingDetailsResponse,
  IAdminSettings,
  IAdminBroadcastGroupFilter,
  IAdminBroadcastGroupListResponse,
  IAdminBroadcastUserGroupResponse,
  IAdminBroadcastGroupListData,
  IBroadcastNotificationApiResponse,
  IAdminBroadcastUserGroupApiData,
  IBroadcastNotificationsData,
  IAdminLogsGenericResponse,
  IAdminLogsFilters,
  IActivityLogsResponse,
  IAdminInfo,
  IPharmacyAdminsListingAPIResponse,
  IPharmacyAdminDetails,
  IAdminPharmacyAdminProfile,
  IPharmaciesInPharmacyAdmin,
  IPharmaciesInPharmacyAdminResponse,
  IBroadcastGroupResponse,
  IPreSubmitDeleteAvailabilities,
  IAdminRegisteredStatistics,
  IAdminCancellationStatistics,
  IAdminFinancialStatistics,
  IAdminWorkshiftStatistics,
  IAdminDailyStatistics,
  IAdminPicture,
  IAllClientResponse,
} from '../models/AdminModels';
import {
  adminBookedPharmacistDetails,
  adminRequestCancellation,
  adminResetValue,
  getActiveUsers,
  getAdminCalendar,
  getAdminEvent,
  getAdminMap,
  getAdminPharmacistRating,
  getAdminRatingItems,
  getApproveDetails,
  getBookAvailablity,
  getIgnoreDetails,
  getMatchingAvailabilities,
  getRequestCancellation,
  getPharmacyDetails,
  getRequestedDetails,
  getRequestedPharmacists,
  getWorkshiftDetails,
  getPharmacistDetails,
  getOtherAvailabilities,
  searchUsers,
  validateExpressBooking,
  getPendingActivations,
  adminGetDeletedUsers,
  adminDeletedUserProfile,
  adminGetSoftwareList,
  adminGetSoftware,
  adminGetPharmacistCapacityList,
  adminGetPharmacistCapacity,
  adminGetContactPreferencesList,
  getAdminConfiguration,
  getSalesforceAdmins,
  adminGetPharmacyChainList,
  adminGetPharmacyChain,
  getPharmacistRegions,
  getPendingCancellations,
  getAdminPharmacistResumes,
  getPendingCancellationDetails,
  getCancelledBookingsDetails,
  getCancelledBookings,
  getActivePharmacistList,
  getRejectedCancellations,
  adminGetAllNotes,
  adminGetNote,
  getPharmacistBookingsList,
  getPharmacistBooking,
  getPharmacistBookingsListPaginations,
  adminShowAvailabilities,
  findMatchingWorkshifts,
  adminShowAvailabilitiesPagination,
  adminPharmacistPictures,
  getPendingActivationsCount,
  getOwnersList,
  setActiveUsersSegment,
  adminGetPharmacistNotes,
  adminGetPharmacistHistory,
  adminGetListOfPharmacies,
  getDeactivatedPharmacists,
  getHiddenPharmacists,
  getDeletedPharmacist,
  getActivePharmacyList,
  getPublishedSoftwareList,
  adminGetListOfPharmacists,
  adminGetPharmacyNotes,
  adminGetPharmacyHistory,
  adminGetIncompatibleMatches,
  adminGetIncompatibleMatchesPagination,
  getActivePharmacistListPagination,
  adminGetPharmacistHistoryPagination,
  getPharmacyWorkshiftsList,
  getPharmacyWorkshiftsListPaginations,
  getDeactivatedPharmacyList,
  getDeletedPharmacyList,
  adminGetPharmacyHistoryPagination,
  adminGetListOfPharmacistsPagination,
  adminGetListOfPharmaciesPagination,
  persistIncompatibleMatchesSearch,
  getAdminReports,
  getAdminReportsFilters,
  getAdminReportsPDF,
  getActiveAdmins,
  getInactiveAdmins,
  getAllPharmacistAvailabilies,
  getAdminProfile,
  getAdminDetails,
  adminGetContactPreference,
  getActivePharmacyListPagination,
  getPharmacistDetailsSearch,
  getPharmacistDetailsSearchFilters,
  adminGetAllPharmacyNotes,
  adminGetPharmacyNote,
  getPharmaciesInfo,
  getRatingDetails,
  getAdminSettings,
  getBroadcastFilter,
  getBroadcastGroup,
  getBroadcastGroupsList,
  getBroadcastGroupUsers,
  getBroadcastNotifications,
  getBroadcastRecipients,
  getBroadcastNotification,
  getWorkshiftLogs,
  getAvailabilitiesLogs,
  getLogsFilters,
  getBookingLogs,
  getActivitiesLogs,
  getAllBroadcastGroups,
  getAdminInfo,
  getPharmacyAdminLists,
  getPharmacyAdminDetails,
  adminGetPharmacyAdminProfile,
  getPharmaciesForPharmacyAdmin,
  getPharmaciesForPharmacyAdminPagination,
  getAppointedPharmaciesList,
  watchlists,
  getBroadcastGroupSelectedUsers,
  getBroadcastGroupSelectedUsersPagination,
  getAdminCalendarFilters,
  getAdminPharmacistVacations,
  adminPreSubmitVacations,
  adminPreSubmitAvailabilities,
  preSubmitDeleteAvailabilities,
  getActiveUserStatistics,
  getCancellationStatistics,
  getFinancialStatistics,
  getWorkshiftStatistics,
  getDailyStats,
  adminGetPharmacyAdminPicture,
  getBroadcastGroupUsersPagination,
  adminGetAllClients,
  adminGetAllClientsPagination,
} from '../actions/adminActions';
import {
  AdminTypes,
  BroadcastNotificationType,
  HelpTypes,
  Language,
  PendingCancellationStatus,
  PharmacistLegendVariant,
  TimeFormat,
  TypeOfUser,
} from '../helpers/Constants';

import {
  ICancelRequestedBooking,
  IMap,
  IPreSubmitAvailabilitiesResponse,
  IPreSubmitVacationsResponse,
  IRating,
  IRatingItems,
} from '../models';
import { ISoftwareResponse } from '../models/Models';

export interface IAdminInitialState {
  appointedPharmacyList: IActivePharmacyList;
  pharmaciesForPharmacyAdmin: IPharmaciesInPharmacyAdminResponse;
  pharmacyAdminProfile: IAdminPharmacyAdminProfile;
  pharmacyAdminList: Array<IPharmacyAdminsListingAPIResponse>;
  pharmacyAdminDetails: IPharmacyAdminDetails;
  allBroadcastGroups: IAdminBroadcastGroupListResponse;
  broadcastNotification: IBroadcastNotificationsData;
  groupRecipientList: Array<IAdminBroadcastUserGroupApiData>;
  broadcastNotifications: IBroadcastNotificationApiResponse;
  broadcastGroup: IAdminBroadcastGroupListData;
  broadcastGroupFilters: IAdminBroadcastGroupFilter;
  broadcastGroupList: IAdminBroadcastGroupListResponse;
  broadcastGroupUsers: IAdminBroadcastUserGroupResponse;
  calendar: Array<IAdminCalendar>;
  adminDetails: IAdminDetailsResponse;
  pharmacistAvailabilities: IAdminShowAvailabilitiesResponse;
  matchingWorkshifts: IMatchingWorkshiftsResponse;
  pendingCancellationDetails: IPendingCancellationCodeDetails;
  activeUsers: Array<IAdminActiveUsersData>;
  pendingCancellations: IPendingCancellationsResponse;
  cancelledBookings: IPendingCancellationsResponse;
  rejectedCancellations: IPendingCancellationsResponse;
  cancelledBookingDetails: IPendingCancellationCodeDetails;
  map: Array<IMap>;
  adminEvent: Array<
    IAvailableWorkshiftData | IAdminRequestData | IBookedPharmacistData
  >;
  contactPreferenceList: IAdminContactPreferences[];
  workshiftDetails: IAdminWorkshiftDetails;
  requestedPharmacists: Array<IAdminRequestedPharmacistsData>;
  requestedDetails: IAdminRequestedDetailsData;
  bookedPharmacistData: IAdminBookedPharmacistDetails;
  adminIgnoreData: IAdminIgnoreData;
  adminApproveData: IAdminApproveDetailsData;
  matchingAvailabilities: IMatchingAvailabilities;
  bookAvailability: IAdminApproveDetailsData;
  cancelBookingMessage: ICancelRequestedBooking;
  ratingDetails: IRating;
  ratingItems: IRatingItems;
  requestCancellation: IRequestCancellationData;
  otherAvailabilities: Array<IMatchingAvailabilitiesData>;
  pharmacyDetails: IPharmacyDetailModel;
  pharmacistDetails: IPharmacistDetailsModel;
  searchedPharmacists: IAdminSearchResponse[];
  searchedPharmacies: IAdminSearchResponse[];
  allSearchedUsers: IAdminSearchResponse[];
  isIncompatibleMatch: boolean | null;
  pendingActivations: IAdminPendingActivationsResponse;
  softwareList: ISoftwareResponse[];
  software: ISoftwareResponse;
  pharmacistCapacityList: IAdminPharmacistCapacityList[];
  pharmacistCapacity: IAdminPharmacistCapacityItem;
  adminConfiguration: IAdminConfiguration;
  salesforceAdmins: ISalesforceAdmin[];
  pharmacyChainList: IAdminPharmacyChainResponse[];
  pharmacyChain: IAdminPharmacyChainResponse;
  pharmacistRegionList: IPharmacistRegionList;
  adminPharmacistResume: IPharmacistResume;
  activePharmacistLists: IAdminPharmacistListApiResponse;
  adminAllNotes: Array<IAdminNotesResponse>;
  adminNote: IAdminNotesResponse;
  pharmacistBookingList: IAdminPharmacistBookingResponse;
  pharmacistBooking: IAdminPharmacistBooking;
  pharmacistPictures: Array<IAdminPharmacistPictures>;
  pendingActivationsCount: IPendingActivationsCount;
  ownersList: Array<IOwnersList>;
  activeUsersSegment: TypeOfUser;
  listOfPharmacies: IAdminListOfPharmaciesResponse;
  pharmacistNotes: Array<IAdminPharmacistNotesData>;
  pharmacistHistory: IAdminPharmacistHistoryResponse;
  deactivatedPharmacists: IAdminPharmacistListResponse;
  hiddenPharmacists: IAdminPharmacistListResponse;
  deletedPharmacists: IAdminPharmacistListResponse;
  activePharmacyList: IActivePharmacyList;
  publishedSoftwareList: Array<IPublishedSoftwareList>;
  listOfPharmacists: IAdminListOfPharmacistsResponse;
  pharmacyNotes: Array<IAdminPharmacistNotesData>;
  pharmacyHistory: IAdminPharmacistHistoryResponse;
  incompatibleMatches: IAdminIncompatibleMatchResponse;
  pharmacyWorkshiftsList: IAdminPharmacyWorkshiftsList;
  deactivatedPharmacyList: IActivePharmacyList;
  deletedPharmacyList: IActivePharmacyList;
  incompatibleMatchesSearch: IAdminIncompatibleMatchesSearch;
  adminReports: IAdminReportsResponse;
  adminReportsFilter: IAdminReportsFilter;
  adminReportsPDF: IAdminReportsPDF;
  adminsList: IAdminListingResponse;
  adminAllAvailabilities: IAdminReportsResponse;
  allPermissions: IPermissionsResponse;
  contactPreference: IAdminContactPreferences;
  pharmacistDetailsSearch: IAdminPharmacistDetailsSearchResponse;
  pharmacistSearchDetailsFilter: IAdminPharmacistDetailsSearchFilters;
  adminPharmacyAllNotes: Array<IAdminPharmacyNotesResponse>;
  adminPharmacyNote: IAdminPharmacyNotesResponse;
  pharmaciesInfo: IAdminPharmaciesInfo;
  pharmacistRatings: Array<IRatingDetailsResponse>;
  adminSettings: IAdminSettings;
  logsFilters: IAdminLogsFilters;
  workshiftLogs: IAdminLogsGenericResponse;
  availabilitiesLogs: IAdminLogsGenericResponse;
  activityLogs: IActivityLogsResponse;
  bookingLogs: IAdminLogsGenericResponse;
  activeUserCount: number;
  adminInfo: IAdminInfo;
  watchlistedWorkshifts: Array<IAvailableWorkshiftData>;
  broadcastGroupSelectedUsers: IBroadcastGroupResponse;
  calendarBanners: Array<string>;
  adminPharmacistVacations: [];
  adminPharmacistPreSubmitVacations: IPreSubmitVacationsResponse;
  adminPreSubmitAvailabilities: IPreSubmitAvailabilitiesResponse;
  activeUserStatistics: IAdminRegisteredStatistics;
  cancellationStatistics: IAdminCancellationStatistics;
  financialStatistics: IAdminFinancialStatistics;
  workshiftStatistics: IAdminWorkshiftStatistics;
  dailyStatistics: IAdminDailyStatistics;
  preSubmitDeleteAvailabilities: IPreSubmitDeleteAvailabilities;
  pharmacyAdminPicture: IAdminPicture;
  allClientUsers: IAllClientResponse;
}

const genericPagination = {
  totalCount: 1,
  page: 1,
  itemPerPage: 1,
};

export const adminInitialState: IAdminInitialState = {
  allClientUsers: {
    ...genericPagination,
    data: [],
  },
  dailyStatistics: {
    bookingRequestCount: 0,
    bookingsCount: 0,
    cancellationRequestCount: 0,
    pharmacistCount: 0,
    pharmacyCount: 0,
    postingCount: 0,
  },
  activeUserStatistics: {
    registeredPharmacies: [],
    registeredPharmacists: [],
    pendigPharmacies: [],
    pendingPharmacists: [],
    approvedPharmacies: [],
    approvedPharmacists: [],
    activePharmacies: [],
    activePharmacists: [],
  },
  cancellationStatistics: {
    raisedByAdmin: 0,
    raisedByPharmacy: 0,
    raisedByPharmacist: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
  },
  financialStatistics: {
    totalPosted: [],
    totalRequested: [],
    totalBooked: [],
    pharmacyBilling: [],
    pharmacistPayout: [],
    pharmaplanProfit: [],
    hours: [],
    averagePharmacyRate: [],
    averagePharmacistRate: [],
    averageHourlyProfit: [],
  },
  workshiftStatistics: {
    createWorkshift: [],
    updateWorkshift: [],
    deleteWorkshift: [],
    updatePharmacy: [],
    createAvailability: [],
    updateAvailability: [],
    deleteAvailability: [],
    requestedByPharmacist: [],
    updatePharmacist: [],
  },
  calendarBanners: [],
  appointedPharmacyList: {
    ...genericPagination,
    data: [],
  },
  pharmaciesForPharmacyAdmin: {
    ...genericPagination,
    data: [],
  },
  pharmacyAdminProfile: { provinces: [] },
  pharmacyAdminDetails: {
    pharmacyCompany: {
      user: {
        language: {
          default: false,
          code: Language.en,
          languageId: '',
          name: '',
        },
        userId: '',
        email: '',
        userName: '',
        personnelId: '',
        languageId: '',
        culture: '',
        createDate: '',
        timeFormat: TimeFormat.twelveHours,
      },
      pharmacyCompanyId: '',
      userId: '',
      name: '',
      description: '',
      appointedPharmacyId: null,
      phone: '',
      mobile: '',
      province: '',
      createDate: '',
      active: false,
    },
    pharmacies: [],
  },
  pharmacyAdminList: [],
  allBroadcastGroups: {
    ...genericPagination,
    data: [],
  },
  groupRecipientList: [],
  broadcastNotification: {
    subject: '',
    sentDate: '',
    message: '',
    type: BroadcastNotificationType.message,
    broadcastGroups: [],
    broadcastMessageId: '',
  },
  broadcastNotifications: {
    ...genericPagination,
    data: [],
  },
  broadcastGroup: {
    broadcastGroupId: '',
    name: '',
    userId: '',
    userName: '',
    createDate: '',
    updateDate: null,
    broadcastGroupUsers: [],
  },
  adminSettings: {
    defaultPharmacistRate: 0,
    permissions: [],
  },
  broadcastGroupUsers: {
    ...genericPagination,
    data: [],
  },
  broadcastGroupList: {
    ...genericPagination,
    data: [],
  },
  broadcastGroupFilters: {
    weekendVolumes: [],
    weekVolumes: [],
  },
  pharmacistSearchDetailsFilter: {
    pharmacistCapacities: [],
  },
  pharmacistDetailsSearch: {
    page: 1,
    totalCount: 1,
    data: [],
    itemPerPage: 1,
  },
  adminDetails: {
    languageId: '',
    email: '',
    phone: '',
    userId: '',
    userName: '',
    userType: AdminTypes.admin,
    permissions: [],
    supportAgentName: '',
  },
  allPermissions: {
    permissions: [],
  },
  isIncompatibleMatch: null,
  adminsList: {
    data: [],
    ...genericPagination,
  },
  incompatibleMatches: {
    totalCount: 1,
    page: 1,
    itemPerPage: 1,
    data: [],
  },
  deletedPharmacists: {
    ...genericPagination,
    data: [],
  },
  hiddenPharmacists: {
    ...genericPagination,
    data: [],
  },
  deactivatedPharmacists: {
    ...genericPagination,
    data: [],
  },
  pharmacistBookingList: {
    ...genericPagination,
    data: [],
  },
  pharmacistAvailabilities: {
    ...genericPagination,
    data: [],
  },
  matchingWorkshifts: {
    availableTimeId: '',
    pharmacistName: '',
    pharmacistId: '',
    startDate: '',
    endDate: '',
    city: '',
    mobile: '',
    matchingAvailabilities: [],
  },
  pharmacistBooking: {
    bookingId: '',
    startDate: '',
    endDate: '',
    languageId: '',
    latitude: '',
    longitude: '',
    paidHourlyRate: 0,
    cost: 0,
    travelAllowance: 0,
    mealAllowance: 0,
    accommodationCosts: 0,
    emergencyCosts: 0,
    pharmacistHourlyRate: 0,
    pharmacyHourlyRate: 0,
    pharmaPlanPercentFees: '',
    logoId: '',
    pharmacyName: '',
    pharmacyChainName: '',
    number: '',
    softwareName: '',
    languageCode: '',
    createDate: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    address: '',
    city: '',
    province: '',
    region: '',
    postalCode: '',
  },
  adminAllNotes: [],
  adminNote: {
    adminPharmacistNoteId: '',
    updateDate: null,
    createDate: '',
    note: '',
    pharmacistId: '',
  },
  contactPreferenceList: [],
  cancelledBookings: {
    ...genericPagination,
    data: [],
  },
  rejectedCancellations: {
    ...genericPagination,
    data: [],
  },
  cancelledBookingDetails: {
    cancellationRequestId: '',
    cancellationId: '',
    bookingId: '',
    requestedBy: '',
    approvedBy: null,
    requestType: '',
    requestReason: '',
    status: PendingCancellationStatus.Normal,
    cancellationReason: null,
    approvedByName: null,
    selectedDate: '',
    cancellationDate: '',
    requestCode: '',
    confirmationCode: null,
    pharmacyId: '',
    pharmacyName: '',
    pharmacistId: '',
    pharmacistName: '',
    workshiftCode: '',
    availabilityCode: '',
    rejectedBy: null,
    rejectionReason: null,
    rejectedByName: null,
    cancellationRequestDate: '',
    cancellationRejectionDate: null,
  },
  pharmacistCapacityList: [],
  pendingCancellationDetails: {
    rejectedBy: null,
    rejectedByName: null,
    rejectionReason: '',
    cancellationRejectionDate: null,
    cancellationRequestDate: '',
    cancellationRequestId: '',
    cancellationId: '',
    bookingId: '',
    requestedBy: '',
    approvedBy: null,
    requestType: '',
    requestReason: '',
    status: PendingCancellationStatus.Normal,
    cancellationReason: null,
    approvedByName: null,
    selectedDate: '',
    cancellationDate: '',
    requestCode: '',
    confirmationCode: null,
    pharmacyId: '',
    pharmacyName: '',
    pharmacistId: '',
    pharmacistName: '',
    workshiftCode: '',
    availabilityCode: '',
  },
  pharmacyChainList: [],
  pendingCancellations: {
    ...genericPagination,
    data: [],
  },
  pharmacyChain: {
    inUse: false,
    logoImage: null,
    logoId: '',
    imageName: '',
    name: '',
    pharmacyChainId: '',
    published: false,
  },
  pharmacistCapacity: {
    createDate: '',
    updateDate: null,
    from: 0,
    to: 0,
    published: false,
    pharmacistCapacityId: '',
  },
  softwareList: [],
  software: {
    inUse: false,
    createDate: '',
    updateDate: null,
    description: '',
    languageId: '',
    name: '',
    published: false,
    softwareId: '',
  },
  calendar: [],
  map: [],
  activeUsers: [],
  adminEvent: [],
  pendingActivations: {
    ...genericPagination,
    data: [],
  },
  ratingDetails: {
    isFavorite: false,
    name: '',
    pharmacistId: '',
    ratings: [],
    reviews: null,
    totalRating: null,
  },
  cancelBookingMessage: {
    message: '',
  },
  ratingItems: {
    favorite: false,
    pharmacistRatingItems: [],
  },
  bookedPharmacistData: {
    pharmacistId: '',
    firstName: '',
    createDate: '',
    lastName: '',
    languageId: '',
    language: {
      code: Language.en,
      default: false,
      languageId: '',
      name: '',
    },
    pharmacistCapacity: {
      pharmacistCapacityId: '',
      from: 0,
      to: 0,
      published: false,
      createDate: '',
      updateDate: null,
    },
    phone: '',
    mobile: '',
    address: '',
    province: '',
    city: '',
    postalCode: '',
    experience: 0,
    licenseNumber: '',
    startDate: '',
    endDate: '',
    paidHourlyRate: 0,
    email: '',
    softwares: [],
    rating: {
      totalRating: 0,
      count: 0,
      pharmacistId: '',
    },
    profilePicture: '',
    pharmacyName: '',
  },
  workshiftDetails: {
    workshiftId: '',
    pharmacyId: '',
    notes: '',
    pharmacistHourlyRate: 0,
    pharmacyName: '',
    emergencyFees: 0,
    banner: '',
    softwareName: '',
    softwareId: '',
    pharmacyCreatedDate: '',
    startDate: '',
    endDate: '',
    paidHourlyRate: 0,
    city: '',
    address: '',
    province: '',
    region: '',
    latitude: '',
    longitude: '',
    pharmacyHourlyRate: 0,
    logoId: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    contactMobile: '',
    contactPhoneExtension: '',
    travelAllowance: 0,
    accommodationAllowance: 0,
    mealAllowance: 0,
    languageId: '',
    note: '',
  },
  requestedPharmacists: [],
  requestedDetails: {
    pharmacistId: '',
    firstName: '',
    lastName: '',
    languageId: '',
    language: {
      code: Language.en,
      default: false,
      languageId: '',
      name: '',
    },
    pharmacistCapacity: {
      pharmacistCapacityId: '',
      from: 0,
      to: 0,
      published: false,
      createDate: '',
      updateDate: null,
    },
    phone: '',
    mobile: '',
    address: '',
    province: '',
    city: '',
    postalCode: '',
    experience: 0,
    startDate: '',
    endDate: '',
    paidHourlyRate: 0,
    email: '',
    bookingCost: 0,
    travelAllowance: 0,
    mealAllowance: 0,
    accommodationAllowance: 0,
    emergencyFees: 0,
    softwares: [],
    rating: {
      totalRating: 0,
      count: 0,
    },
    profilePicture: '',
    licenseNumber: '',
  },
  adminIgnoreData: {
    bookingId: '',
    pharmacyId: '',
    pharmacistId: '',
    pharmacyName: '',
    pharmacistName: '',
    startDate: '',
    endDate: '',
  },
  adminApproveData: {
    bookingId: '',
    pharmacyId: '',
    pharmacistHourlyRate: 0,
    pharmacyHourlyRate: 0,
    pharmacistId: '',
    pharmaList: [],
    pharmaId: '',
    pharmacyName: '',
    pharmacistName: '',
    startDate: '',
    endDate: '',
  },
  matchingAvailabilities: {
    city: '',
    endDate: '',
    logoId: '',
    matchingAvailabilities: [],
    pharmacyName: '',
    startDate: '',
    typeReplacement: 0,
    workShiftId: '',
  },
  bookAvailability: {
    bookingId: '',
    pharmacyId: '',
    pharmacistId: '',
    pharmaList: [],
    pharmacistHourlyRate: 0,
    pharmacyHourlyRate: 0,
    pharmaId: '',
    pharmacyName: '',
    pharmacistName: '',
    startDate: '',
    endDate: '',
  },
  requestCancellation: {
    startDate: '',
    endDate: '',
    pharmacyName: '',
    success: null,
    bookingId: '',
    cancelationRequestId: '',
    code: '',
    message: '',
  },
  otherAvailabilities: [],
  pharmacyDetails: {
    pharmacy: {
      pharmacyId: '',
      latitude: null,
      longitude: null,
      pharmacyChainId: '',
      email: '',
      languageId: '',
      pharmacyHourlyRate: 0,
      timeFormat: TimeFormat.twentyFourHours,
      name: '',
      logoId: '',
      banner: '',
      pharmacyShortName: null,
      number: null,
      softwareId: '',
      selectedContactPreferences: [],
      sendReminder: false,
      code: '',
      administrator: null,
      weekVolume: '',
      weekendVolume: '',
      weekDayPharmacists: '',
      weekNightPharmacists: '',
      weekendDayPharmacists: '',
      weekendNightPharmacists: '',
      weekDayTechs: '',
      weekNightTechs: '',
      weekendDayTechs: '',
      weekendNightTechs: '',
      phone: '',
      emergencyPhone: '',
      postalCode: '',
      fax: '',
      address: '',
      city: '',
      province: '',
      region: '',
      regionId: '',
      contactEmail: '',
      primaryContactName: '',
      primaryContactPhone: '',
      primaryContactPhoneExt: '',
      primaryContactMobile: null,
      primaryContactEmail: '',
      secondaryContactName: '',
      secondaryContactPhone: null,
      secondaryContactPhoneExt: null,
      secondaryContactMobile: null,
      secondaryContactEmail: null,
      accountingContactName: '',
      accountingContactPhone: '',
      accountingContactPhoneExt: '',
      accountingContactMobile: '',
      accountingContactEmail: '',
      hourlyRate: 0,
      adminNotes: '',
      useLSE: false,
      hourlyRateTech: 0,
      pharmaPlanPercentFees: 0,
      cancellationPercentFees: 0,
      noCancellationPeriod: 0,
      communicationEmail: '',
    },
    softwares: [],
    provinces: [],
    contactPreferences: [],
  },
  activePharmacistLists: {
    ...genericPagination,
    data: [],
  },
  pharmacistDetails: {
    contactPreferences: [],
    pharmacist: {
      latitude: null,
      longitude: null,
      email: '',
      selectedContactPreferences: [],
      preferdLanguage: '',
      timeFormat: TimeFormat.twentyFourHours,
      firstName: '',
      lastName: '',
      licenseNumber: '',
      pharmacistCapacityId: '',
      rating: {
        pharmacistId: '',
        totalRating: 0,
        count: 0,
      },
      graduationYear: 0,
      yearsOfExperience: 0,
      incorporated: false,
      englishLevel: 0,
      frenchLevel: 0,
      otherLanguages: '',
      address: '',
      phone: '',
      mobile: '',
      city: '',
      postalCode: '',
      province: '',
      prefferedDistanceRange: 0,
      availabilityReminder: false,
      preferredReplacementDuration: '',
      preferredWorkingDays: '',
      code: '',
      picture: '',
      hourlyRate: 0,
      selectedSoftwares: [],
      pictureName: '',
      resumeName: '',
      cancellationPercentFees: 0,
      noCancellationPeriod: 0,
      bookingStateExpiration: 0,
      adminNotes: '',
      pharmacistId: '',
    },
    provinces: [],
    pharmacistCapacities: [],
    prefferedReplacementDurations: [],
  },
  searchedPharmacies: [],
  searchedPharmacists: [],
  allSearchedUsers: [],
  adminConfiguration: {
    enableChat: false,
    pharmaPlanConfigurationId: '',
    ratePharmacistDays: 0,
    minHourWorkshift: 0,
    pharmacyRate: 0,
    technicianRate: 0,
    pharmacistRate: 0,
    representativeName: '',
    representativeEmail: '',
    representativePhone: '',
    representativePhoneExt: '',
    salesForceUserName: '',
    salesForcePassword: '',
    salesForceSecurityToken: '',
    salesForceURL: '',
    envoiceBCCAdmin: false,
    salesForceActive: false,
    showBookingDetails: false,
    noCancellationPeriod: 0,
    bookingStateExpiration: 0,
    pharmaPlanPercentFees: 0,
    cancellationPercentFees: 0,
    freeCancellationPeriod: 0,
    reminder1After: 0,
    reminder2Before: 0,
  },
  salesforceAdmins: [],
  pharmacistRegionList: {
    allRegions: [],
    selectedRegions: [],
  },
  adminPharmacistResume: {
    fileName: '',
    contentType: '',
    resume: '',
  },
  pharmacistPictures: [],
  pendingActivationsCount: {
    pharmacistCount: 0,
    pharmacyCount: 0,
  },
  ownersList: [],
  activeUsersSegment: TypeOfUser.pharmacist,
  listOfPharmacies: {
    ...genericPagination,
    data: [],
  },
  pharmacistNotes: [],
  pharmacistHistory: {
    pharmacistId: '',
    totalCount: 0,
    pharmacyId: '',
    data: [],
  },
  activePharmacyList: {
    ...genericPagination,
    data: [],
  },
  publishedSoftwareList: [],
  listOfPharmacists: {
    ...genericPagination,
    data: [],
  },
  pharmacyNotes: [],
  pharmacyHistory: {
    pharmacistId: '',
    totalCount: 0,
    pharmacyId: '',
    data: [],
  },
  pharmacyWorkshiftsList: {
    ...genericPagination,
    data: [],
  },
  deactivatedPharmacyList: {
    ...genericPagination,
    data: [],
  },
  deletedPharmacyList: {
    ...genericPagination,
    data: [],
  },
  incompatibleMatchesSearch: {
    pharmacies: '',
    pharmacyId: '',
    pharmacySelected: '',
  },
  adminReports: {
    ...genericPagination,
    type: AdminLegendVariant.booked,
    data: [],
  },
  adminAllAvailabilities: {
    ...genericPagination,
    type: PharmacistLegendVariant.availability,
    data: [],
  },
  adminReportsFilter: {
    replacementTypes: [],
    availabilityStatuses: [],
    sortBy: [],
  },
  adminReportsPDF: {
    pdfContent: '',
  },
  contactPreference: {
    contactPreferenceId: '',
    published: false,
    inUse: false,
    name: '',
  },
  adminPharmacyAllNotes: [],
  adminPharmacyNote: {
    adminPharmacyNoteId: '',
    updateDate: null,
    createDate: '',
    note: '',
    pharmacyId: '',
  },
  pharmaciesInfo: {
    hourlyRate: 0,
    pharmacyHourlyRate: 0,
  },
  pharmacistRatings: [],
  logsFilters: {
    availabilityActions: [],
    workshiftActions: [],
    bookingActions: [],
    activityActions: [],
  },
  workshiftLogs: {
    ...genericPagination,
    data: [],
  },
  availabilitiesLogs: {
    ...genericPagination,
    data: [],
  },
  activityLogs: {
    ...genericPagination,
    data: [],
  },
  bookingLogs: {
    ...genericPagination,
    data: [],
  },
  activeUserCount: 0,
  adminInfo: {
    phone: '',
    userName: '',
    userId: '',
    languageId: '',
    email: '',
    id: '',
    code: Language.en,
  },
  watchlistedWorkshifts: [],
  broadcastGroupSelectedUsers: {
    group: {
      broadcastGroupId: '',
      userId: '',
      userName: '',
      groupUsersCount: 0,
      name: '',
      createDate: '',
      updateDate: '',
    },
    ...genericPagination,
    data: [],
  },
  adminPharmacistVacations: [],
  adminPharmacistPreSubmitVacations: {
    hasAvailable: false,
    hasBooking: false,
    hasRequested: false,
  },
  adminPreSubmitAvailabilities: {
    isPharmacistOnVacation: false,
  },
  preSubmitDeleteAvailabilities: {
    isAvaiabailityRequested: false,
  },
  pharmacyAdminPicture: {
    contentType: '',
    image: '',
    name: '',
  },
};

let prev: TypeOfUser | null = null;

const adminReducer = createReducer(adminInitialState, (builder) => {
  builder
    .addCase(getAdminCalendar.fulfilled, (state, action) => {
      state.calendar = action.payload;
    })
    .addCase(getAdminMap.fulfilled, (state, action) => {
      state.map = action.payload;
    })
    .addCase(getActiveUsers.fulfilled, (state, action) => {
      const { data, pharmacyCount, pharmacistCount } = action.payload ?? {};
      const { user, page } = action.meta.arg ?? {};

      state.activeUsers =
        prev === user && page !== 1 ? [...state.activeUsers, ...data] : data;
      prev = user;

      if (user === TypeOfUser.pharmacist) {
        state.activeUserCount = pharmacyCount;
      } else {
        state.activeUserCount = pharmacistCount;
      }
    })
    .addCase(getAdminEvent.fulfilled, (state, action) => {
      state.adminEvent = action.payload;
    })
    .addCase(getRequestedPharmacists.fulfilled, (state, action) => {
      state.requestedPharmacists = action.payload;
    })
    .addCase(getRequestedDetails.fulfilled, (state, action) => {
      state.requestedDetails = action.payload;
    })
    .addCase(getIgnoreDetails.fulfilled, (state, action) => {
      state.adminIgnoreData = action.payload;
    })
    .addCase(getApproveDetails.fulfilled, (state, action) => {
      state.adminApproveData = action.payload;
    })
    .addCase(adminBookedPharmacistDetails.fulfilled, (state, action) => {
      state.bookedPharmacistData = action.payload;
    })
    .addCase(adminResetValue, (state, action) => {
      action.payload.map(
        (item) =>
          // @ts-expect-error: REDUCERS HAVE WRITABLE INNATE TYPE
          (state[item] =
            adminInitialState[item as keyof typeof adminInitialState])
      );
    })
    .addCase(getAdminRatingItems.fulfilled, (state, action) => {
      state.ratingItems = action.payload;
    })
    .addCase(getAdminPharmacistRating.fulfilled, (state, action) => {
      state.ratingDetails = action.payload;
    })
    .addCase(getWorkshiftDetails.fulfilled, (state, action) => {
      state.workshiftDetails = action.payload;
    })
    .addCase(getMatchingAvailabilities.fulfilled, (state, action) => {
      state.matchingAvailabilities = action.payload;
    })
    .addCase(getOtherAvailabilities.fulfilled, (state, action) => {
      state.otherAvailabilities = action.payload;
    })
    .addCase(getBookAvailablity.fulfilled, (state, action) => {
      state.bookAvailability = action.payload;
    })
    .addCase(adminRequestCancellation.fulfilled, (state, action) => {
      state.cancelBookingMessage = action.payload;
    })
    .addCase(searchUsers.fulfilled, (state, action) => {
      const { userTypes } = action.meta.arg;
      switch (JSON.stringify(userTypes)) {
        case JSON.stringify([HelpTypes.pharmacist]):
          state.searchedPharmacists = action.payload;
          break;
        case JSON.stringify([HelpTypes.pharmacy]):
          state.searchedPharmacies = action.payload;
          break;
        case JSON.stringify([HelpTypes.pharmacy, HelpTypes.pharmacist]):
          state.allSearchedUsers = action.payload;
          break;
        default:
          state.allSearchedUsers = action.payload;
      }
    })
    .addCase(validateExpressBooking.fulfilled, (state, action) => {
      state.isIncompatibleMatch = action.payload.isIncompatibleMatch;
    })
    .addCase(getRequestCancellation.fulfilled, (state, action) => {
      state.requestCancellation = action.payload;
    })
    .addCase(getPharmacyDetails.fulfilled, (state, action) => {
      state.pharmacyDetails = action.payload;
    })
    .addCase(getPendingActivations.fulfilled, (state, action) => {
      state.pendingActivations = action.payload;
    })
    .addCase(adminGetDeletedUsers.fulfilled, (state, action) => {
      state.pendingActivations = action.payload;
    })
    .addCase(adminDeletedUserProfile.fulfilled, (state, action) => {
      if (action.payload.pharmacist) {
        state.pharmacistDetails = action.payload;
      } else if (action.payload.pharmacy) {
        state.pharmacyDetails = action.payload;
      }
    })
    .addCase(adminGetSoftwareList.fulfilled, (state, action) => {
      state.softwareList = action.payload;
    })
    .addCase(adminGetSoftware.fulfilled, (state, action) => {
      state.software = action.payload;
    })
    .addCase(adminGetPharmacistCapacityList.fulfilled, (state, action) => {
      state.pharmacistCapacityList = action.payload;
    })
    .addCase(adminGetPharmacistCapacity.fulfilled, (state, action) => {
      state.pharmacistCapacity = action.payload;
    })
    .addCase(adminGetContactPreferencesList.fulfilled, (state, action) => {
      state.contactPreferenceList = action.payload;
    })
    .addCase(adminGetPharmacyChainList.fulfilled, (state, action) => {
      state.pharmacyChainList = action.payload;
    })
    .addCase(adminGetPharmacyChain.fulfilled, (state, action) => {
      state.pharmacyChain = action.payload;
    })
    .addCase(getPharmacistDetails.fulfilled, (state, action) => {
      state.pharmacistDetails = action.payload;
    })
    .addCase(getAdminConfiguration.fulfilled, (state, action) => {
      state.adminConfiguration = action.payload;
    })
    .addCase(getSalesforceAdmins.fulfilled, (state, action) => {
      state.salesforceAdmins = action.payload;
    })
    .addCase(getPendingCancellations.fulfilled, (state, action) => {
      state.pendingCancellations = action.payload;
    })
    .addCase(getPendingCancellationDetails.fulfilled, (state, action) => {
      state.pendingCancellationDetails = action.payload;
    })
    .addCase(getCancelledBookingsDetails.fulfilled, (state, action) => {
      state.cancelledBookingDetails = action.payload;
    })
    .addCase(getCancelledBookings.fulfilled, (state, action) => {
      state.cancelledBookings = action.payload;
    })
    .addCase(getRejectedCancellations.fulfilled, (state, action) => {
      state.rejectedCancellations = action.payload;
    })
    .addCase(getPharmacistRegions.fulfilled, (state, action) => {
      state.pharmacistRegionList = action.payload;
    })
    .addCase(getActivePharmacistList.fulfilled, (state, action) => {
      state.activePharmacistLists = action.payload;
    })
    .addCase(adminGetAllNotes.fulfilled, (state, action) => {
      state.adminAllNotes = action.payload;
    })
    .addCase(adminGetNote.fulfilled, (state, action) => {
      state.adminNote = action.payload;
    })
    .addCase(getPharmacistBookingsList.fulfilled, (state, action) => {
      state.pharmacistBookingList = action.payload;
    })
    .addCase(
      getPharmacistBookingsListPaginations.fulfilled,
      (state, action) => {
        state.pharmacistBookingList.data.push(...action.payload?.data);
      }
    )
    .addCase(getPharmacistBooking.fulfilled, (state, action) => {
      state.pharmacistBooking = action.payload;
    })
    .addCase(adminShowAvailabilities.fulfilled, (state, action) => {
      state.pharmacistAvailabilities = action.payload;
    })
    .addCase(adminShowAvailabilitiesPagination.fulfilled, (state, action) => {
      state.pharmacistAvailabilities.data.push(...action.payload?.data);
    })
    .addCase(findMatchingWorkshifts.fulfilled, (state, action) => {
      state.matchingWorkshifts = action.payload;
    })
    .addCase(getAdminPharmacistResumes.fulfilled, (state, action) => {
      state.adminPharmacistResume = action.payload;
    })
    .addCase(adminPharmacistPictures.fulfilled, (state, action) => {
      state.pharmacistPictures = action.payload;
    })
    .addCase(getPendingActivationsCount.fulfilled, (state, action) => {
      state.pendingActivationsCount = action.payload;
    })
    .addCase(getOwnersList.fulfilled, (state, action) => {
      state.ownersList = action.payload;
    })
    .addCase(adminGetPharmacistNotes.fulfilled, (state, action) => {
      state.pharmacistNotes = action.payload;
    })
    .addCase(adminGetPharmacistHistory.fulfilled, (state, action) => {
      state.pharmacistHistory = action.payload;
    })
    .addCase(adminGetPharmacistHistoryPagination.fulfilled, (state, action) => {
      state.pharmacistHistory.data.push(...action.payload.data);
    })
    .addCase(adminGetListOfPharmacies.fulfilled, (state, action) => {
      state.listOfPharmacies = action.payload;
    })
    .addCase(adminGetListOfPharmaciesPagination.fulfilled, (state, action) => {
      state.listOfPharmacies.data.push(...action.payload?.data);
    })
    .addCase(getDeactivatedPharmacists.fulfilled, (state, action) => {
      state.deactivatedPharmacists = action.payload;
    })
    .addCase(getHiddenPharmacists.fulfilled, (state, action) => {
      state.hiddenPharmacists = action.payload;
    })
    .addCase(getDeletedPharmacist.fulfilled, (state, action) => {
      state.deletedPharmacists = action.payload;
    })
    .addCase(setActiveUsersSegment, (state, action) => {
      state.activeUsersSegment = action.payload;
    })
    .addCase(getActivePharmacyList.fulfilled, (state, action) => {
      state.activePharmacyList = action.payload;
    })
    .addCase(getPublishedSoftwareList.fulfilled, (state, action) => {
      state.publishedSoftwareList = action.payload;
    })
    .addCase(adminGetListOfPharmacists.fulfilled, (state, action) => {
      state.listOfPharmacists = action.payload;
    })
    .addCase(adminGetListOfPharmacistsPagination.fulfilled, (state, action) => {
      state.listOfPharmacists.data.push(...action.payload?.data);
    })
    .addCase(getActivePharmacyListPagination.fulfilled, (state, action) => {
      state.activePharmacyList.data.push(...action.payload?.data);
    })
    .addCase(adminGetPharmacyNotes.fulfilled, (state, action) => {
      state.pharmacyNotes = action.payload;
    })
    .addCase(adminGetIncompatibleMatches.fulfilled, (state, action) => {
      state.incompatibleMatches = action.payload;
    })
    .addCase(
      adminGetIncompatibleMatchesPagination.fulfilled,
      (state, action) => {
        state.incompatibleMatches.data.push(...action.payload.data);
      }
    )
    .addCase(getActivePharmacistListPagination.fulfilled, (state, action) => {
      state.activePharmacistLists.data.push(...action.payload.data);
    })
    .addCase(adminGetPharmacyHistory.fulfilled, (state, action) => {
      state.pharmacyHistory = action.payload;
    })
    .addCase(adminGetPharmacyHistoryPagination.fulfilled, (state, action) => {
      state.pharmacyHistory.data.push(...action.payload?.data);
    })
    .addCase(getPharmacyWorkshiftsList.fulfilled, (state, action) => {
      state.pharmacyWorkshiftsList = action.payload;
    })
    .addCase(
      getPharmacyWorkshiftsListPaginations.fulfilled,
      (state, action) => {
        state.pharmacyWorkshiftsList.data.push(...action.payload?.data);
      }
    )
    .addCase(getDeactivatedPharmacyList.fulfilled, (state, action) => {
      state.deactivatedPharmacyList = action.payload;
    })
    .addCase(getDeletedPharmacyList.fulfilled, (state, action) => {
      state.deletedPharmacyList = action.payload;
    })
    .addCase(getActiveAdmins.fulfilled, (state, action) => {
      state.adminsList = action.payload;
    })
    .addCase(getInactiveAdmins.fulfilled, (state, action) => {
      state.adminsList = action.payload;
    })
    .addCase(persistIncompatibleMatchesSearch, (state, action) => {
      state.incompatibleMatchesSearch = action.payload;
    })
    .addCase(getAdminReports.fulfilled, (state, action) => {
      state.adminReports = action.payload;
    })
    .addCase(getAdminReportsFilters.fulfilled, (state, action) => {
      state.adminReportsFilter = action.payload;
    })
    .addCase(getAllPharmacistAvailabilies.fulfilled, (state, action) => {
      state.adminAllAvailabilities = action.payload;
    })
    .addCase(getAdminProfile.fulfilled, (state, action) => {
      state.allPermissions = action.payload;
    })
    .addCase(getAdminDetails.fulfilled, (state, action) => {
      state.adminDetails = action.payload;
    })
    .addCase(getAdminReportsPDF.fulfilled, (state, action) => {
      state.adminReportsPDF = action.payload;
    })
    .addCase(getPharmacistDetailsSearch.fulfilled, (state, action) => {
      state.pharmacistDetailsSearch = action.payload;
    })
    .addCase(getPharmacistDetailsSearchFilters.fulfilled, (state, action) => {
      state.pharmacistSearchDetailsFilter = action.payload;
    })
    .addCase(adminGetContactPreference.fulfilled, (state, action) => {
      state.contactPreference = action.payload;
    })
    .addCase(adminGetAllPharmacyNotes.fulfilled, (state, action) => {
      state.adminPharmacyAllNotes = action.payload;
    })
    .addCase(adminGetPharmacyNote.fulfilled, (state, action) => {
      state.adminPharmacyNote = action.payload;
    })
    .addCase(getPharmaciesInfo.fulfilled, (state, action) => {
      state.pharmaciesInfo = action.payload;
    })
    .addCase(getAdminSettings.fulfilled, (state, action) => {
      state.adminSettings = action.payload;
    })
    .addCase(getBroadcastFilter.fulfilled, (state, action) => {
      state.broadcastGroupFilters = action.payload;
    })
    .addCase(getBroadcastGroupsList.fulfilled, (state, action) => {
      state.broadcastGroupList = action.payload;
    })
    .addCase(getBroadcastGroupUsers.fulfilled, (state, action) => {
      state.broadcastGroupUsers = action.payload;
    })
    .addCase(getBroadcastGroupUsersPagination.fulfilled, (state, action) => {
      const { data } = action.payload ?? {};
      state.broadcastGroupUsers.data.push(...data);
    })
    .addCase(getBroadcastGroup.fulfilled, (state, action) => {
      state.broadcastGroup = action.payload;
    })
    .addCase(getBroadcastNotifications.fulfilled, (state, action) => {
      state.broadcastNotifications = action.payload;
    })
    .addCase(getBroadcastRecipients.fulfilled, (state, action) => {
      state.groupRecipientList = action.payload;
    })
    .addCase(getBroadcastNotification.fulfilled, (state, action) => {
      state.broadcastNotification = action.payload;
    })
    .addCase(getRatingDetails.fulfilled, (state, action) => {
      state.pharmacistRatings = action.payload;
    })
    .addCase(getWorkshiftLogs.fulfilled, (state, action) => {
      state.workshiftLogs = action.payload;
    })
    .addCase(getAvailabilitiesLogs.fulfilled, (state, action) => {
      state.availabilitiesLogs = action.payload;
    })
    .addCase(getBookingLogs.fulfilled, (state, action) => {
      state.bookingLogs = action.payload;
    })
    .addCase(getActivitiesLogs.fulfilled, (state, action) => {
      state.activityLogs = action.payload;
    })
    .addCase(getAllBroadcastGroups.fulfilled, (state, action) => {
      state.allBroadcastGroups = action.payload;
    })
    .addCase(getPharmacyAdminLists.fulfilled, (state, action) => {
      state.pharmacyAdminList = action.payload;
    })
    .addCase(getPharmacyAdminDetails.fulfilled, (state, action) => {
      state.pharmacyAdminDetails = action.payload;
    })
    .addCase(adminGetPharmacyAdminProfile.fulfilled, (state, action) => {
      state.pharmacyAdminProfile = action.payload;
    })
    .addCase(getPharmaciesForPharmacyAdmin.fulfilled, (state, action) => {
      state.pharmaciesForPharmacyAdmin = action.payload;
    })
    .addCase(
      getPharmaciesForPharmacyAdminPagination.fulfilled,
      (state, action) => {
        state.pharmaciesForPharmacyAdmin.data.push(...action.payload?.data);
      }
    )
    .addCase(getLogsFilters.fulfilled, (state, action) => {
      state.logsFilters = action.payload;
    })
    .addCase(getAdminInfo.fulfilled, (state, action) => {
      state.adminInfo = action.payload;
    })
    .addCase(getAppointedPharmaciesList.fulfilled, (state, action) => {
      state.appointedPharmacyList = action.payload;
    })
    .addCase(watchlists.fulfilled, (state, action) => {
      state.watchlistedWorkshifts = action.payload;
    })
    .addCase(getBroadcastGroupSelectedUsers.fulfilled, (state, action) => {
      state.broadcastGroupSelectedUsers = action.payload;
    })
    .addCase(getAdminCalendarFilters.fulfilled, (state, action) => {
      state.calendarBanners = action.payload;
    })
    .addCase(
      getBroadcastGroupSelectedUsersPagination.fulfilled,
      (state, action) => {
        state.broadcastGroupSelectedUsers.data.push(...action.payload?.data);
      }
    )
    .addCase(getAdminPharmacistVacations.fulfilled, (state, action) => {
      state.adminPharmacistVacations = action.payload;
    })
    .addCase(adminPreSubmitVacations.fulfilled, (state, action) => {
      state.adminPharmacistPreSubmitVacations = action.payload;
    })
    .addCase(adminPreSubmitAvailabilities.fulfilled, (state, action) => {
      state.adminPreSubmitAvailabilities = action.payload;
    })
    .addCase(getActiveUserStatistics.fulfilled, (state, action) => {
      state.activeUserStatistics = action.payload;
    })
    .addCase(getCancellationStatistics.fulfilled, (state, action) => {
      state.cancellationStatistics = action.payload;
    })
    .addCase(getFinancialStatistics.fulfilled, (state, action) => {
      state.financialStatistics = action.payload;
    })
    .addCase(getWorkshiftStatistics.fulfilled, (state, action) => {
      state.workshiftStatistics = action.payload;
    })
    .addCase(getDailyStats.fulfilled, (state, action) => {
      state.dailyStatistics = action.payload;
    })
    .addCase(preSubmitDeleteAvailabilities.fulfilled, (state, action) => {
      state.preSubmitDeleteAvailabilities = action.payload;
    })
    .addCase(adminGetAllClients.fulfilled, (state, action) => {
      state.allClientUsers = action.payload;
    })
    .addCase(adminGetAllClientsPagination.fulfilled, (state, action) => {
      const { data } = action.payload ?? {};
      state.allClientUsers.data.push(...data);
    })
    .addCase(adminGetPharmacyAdminPicture.fulfilled, (state, action) => {
      state.pharmacyAdminPicture = action.payload;
    });
});

export default adminReducer;
